const bankNameList = [
  'hsbc',
  'barclays',
  'monzo',
  'revolut',
  'natwest',
  'santander',
  'tesco',
  'tsb',
  'virgin',
  'yorkshire',
  'first direct',
  'metro',
  'cooperative',
  'coop',
  'starling',
  'nationwide',
  'lloyds',
];

export const isBankName = str => bankNameList.includes(str?.toLowerCase());
