import React from 'react';
import PropTypes from 'prop-types';
import { CommonLabel } from '../CommonLabel';

const LaptopRobotGraphic = ({ graphicData }) => {
  const { bgCircles, labels, bgImages, robotLabel } = graphicData;
  return graphicData ? (
    <div className="tw-relative tw-flex tw-w-full tw--mt-16 tw--mb-56 slg:tw--mb-20 sxl:tw-mb-0 xs:tw--mt-8 md:tw-mt-0 xxs:tw--mb-48 xs:tw--mb-32 slg:tw-w-7/12 tw-h-4xl md:tw-mb-0 ">
      {bgCircles &&
        bgCircles.map((item, index) => {
          return <div key={`circle_${index + 1}`} className={item.classNames}></div>;
        })}

      {labels &&
        labels.map((item, index) => {
          return (
            <div key={`${item.content}_${index + 1}`} className={item?.boxClassNames}>
              <div className={item?.arrow?.classNames}>
                <img className="tw-w-full" alt="arrow" src={item?.arrow?.icon} />
              </div>
              <CommonLabel
                content={item?.content}
                maxWidth={item.labelMaxWidth}
                bgColor={item.labelColor}
                classNames={item.classNames}
                breakWord
              />
            </div>
          );
        })}

      {bgImages &&
        bgImages.map((item, index) => {
          return <img key={`bg_${index + 1}`} className={item.classNames} src={item.src} alt="Bg Img" />;
        })}

      {robotLabel && (
        <div className={robotLabel?.classNames}>
          <div className="tw-relative tw-self-start tw-w-9/12 xs:tw-w-10/12">
            <CommonLabel
              content={robotLabel?.content}
              classNames={robotLabel?.labelClassNames}
              customLabelClasses={robotLabel?.labelContentClassNames}
              bgColor={robotLabel?.labelColor}
              withTail
              breakWord
            />
          </div>

          <div className="tw-relative tw-w-3/12 xs:tw-w-2/12">
            <div className={robotLabel?.iconClassNames}>
              <img className="tw-w-full" alt="Bg Icon" src={robotLabel?.icon} />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

LaptopRobotGraphic.propTypes = {
  graphicData: PropTypes.shape({
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        boxClassNames: PropTypes.string,
        labelColor: PropTypes.string,
        classNames: PropTypes.string,
        labelMaxWidth: PropTypes.string,
        arrow: PropTypes.shape({
          icon: PropTypes.string,
          classNames: PropTypes.string,
        }),
      }),
    ),
    bgCircles: PropTypes.arrayOf(
      PropTypes.shape({
        classNames: PropTypes.string,
      }),
    ),
    bgImages: PropTypes.arrayOf(
      PropTypes.shape({
        classNames: PropTypes.string,
        src: PropTypes.string,
      }),
    ),
    robotLabel: PropTypes.shape({
      classNames: PropTypes.string,
      labelColor: PropTypes.string,
      labelContentClassNames: PropTypes.string,
      labelClassNames: PropTypes.string,
      content: PropTypes.string,
      icon: PropTypes.string,
      iconClassNames: PropTypes.string,
    }),
  }),
};

LaptopRobotGraphic.defaultProps = {
  graphicData: null,
};

export default LaptopRobotGraphic;
