import React from 'react';
import PropTypes from 'prop-types';
import { CommonLabel } from '../CommonLabel';

const UserCommentGraphic = ({ graphicData }) => {
  const { labels, bgItems } = graphicData;
  return graphicData ? (
    <div className="tw-relative tw-w-full slg:tw-w-7/12 tw-h-4xl tw--mb-80 xs:tw--mb-60">
      {labels &&
        labels.map((item, index) => (
          <div className={item.boxClassNames} key={`${item.content}_${index + 1}`}>
            <CommonLabel
              content={item.content}
              subContent={item.subContent}
              icon={item.icon}
              bgColor={item.labelColor}
              boldFont
              maxWidth={item.labelMaxWidth}
              labelStyle={item.labelStyle}
              roundCornerPosition={item.roundCornerPosition}
              classNames={`${item.classNames}`}
            />
          </div>
        ))}
      {bgItems && (
        <div className={bgItems.classNames}>
          {bgItems?.bgImages && bgItems?.bgImages?.map((item, index) => <div key={`$user_comment_bg_${index + 1}`} className={item.classNames} style={{ 'background-image': `url(${item.src})` }} ></div>)}
          {bgItems?.rings && bgItems?.rings?.map((item, index) => <div key={`$user_comment_ring_${index + 1}`} className={item.classNames}></div>)}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

UserCommentGraphic.propTypes = {
  graphicData: PropTypes.shape({
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        subContent: PropTypes.string,
        icon: PropTypes.string,
        boxClassNames: PropTypes.string,
        classNames: PropTypes.string,
        labelColor: PropTypes.string,
        labelStyle: PropTypes.string,
        labelMaxWidth: PropTypes.string,
        roundCornerPosition: PropTypes.string,
      }),
    ),
    bgItems: PropTypes.shape({
      rings: PropTypes.arrayOf(
        PropTypes.shape({
          classNames: PropTypes.string,
        }),
      ),
      bgImages: PropTypes.arrayOf(
        PropTypes.shape({
          classNames: PropTypes.string,
          src: PropTypes.string,
        }),
      ),
    }),
  }),
};

UserCommentGraphic.defaultProps = {
  graphicData: null,
};

export default UserCommentGraphic;
