import React from 'react';
const MenusInFooter = ({ menuList, totalColumn }) => {
  return (
    <div className={`tw-grid ${totalColumn === 2 ? 'tw-grid-cols-2' : 'tw-grid-cols-4'} tw-gap-x-4`}>
      {menuList.map((column, columnIndex) => (
        <div className="column" key={`column_${columnIndex + 1}`}>
          {column.map(menuGroup => (
            <div className="menu-group tw-mb-12 tw-flex tw-flex-col" key={menuGroup.id}>
              <h3 className="tw-menu-title  tw-font-bold !tw-leading-none">{menuGroup.title}</h3>
              {menuGroup.pages.map((menuItem, index) => (
                <a
                  className={`tw-text-primary-100 tw-text-xs md:tw-text-sm sxl:tw-text-base tw-font-semibold tw-mb-4 !tw-leading-none
                  ${index === menuGroup.pages.length - 1 ? 'tw-mb-0' : ''}`}
                  href={menuItem.uri}
                  key={menuItem.uri}
                >
                  {menuItem.title}
                </a>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default MenusInFooter;
