import React, { useState, useEffect, useRef } from 'react';
import RateV2 from 'components/RateV2/RateV2';
import CustomerRateItem from 'components/CustomerRateItem/CustomerRateItem';
import { mobileAndTabletCheck } from 'helpers/mobileAndTabletCheck';
const CustomerRateList = ({ rateList }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [translateX, setTranslateX] = useState('-40%');
  const listContainerRef = useRef(null);
  const [canScroll, setCanScroll] = useState(false);
  const [scrollbarClassName, setScrollbarClassName] = useState('');
  const renderArrow = (disabled = false) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
          d="M5 12.2324H19M5 12.2324L11 18.2324M5 12.2324L11 6.23242"
          stroke={disabled ? '#9E9E9E' : 'black'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const toPrevious = () => {
    setCanScroll(true);
    if (activeIndex > 0) {
      if (activeIndex === 1) {
        setTranslateX('0');
      }
      setActiveIndex(activeIndex - 1);
    }
  };
  const toNext = () => {
    setCanScroll(true);
    if (activeIndex < rateList.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };
  const initTranslateX = containerWidth => {
    if (containerWidth && containerWidth <= 1280) {
      setTranslateX('-52%');
    } else if (containerWidth > 1920) {
      setTranslateX('calc(-32% - 2rem)');
    } else {
      setTranslateX('-40%');
    }
  };
  useEffect(() => {
    initTranslateX(document.body.offsetWidth);
    const isMobileOrTablet = mobileAndTabletCheck();
    if (isMobileOrTablet) {
      setScrollbarClassName('tw-scrollbar-hidden');
    } else {
      setScrollbarClassName('tw-scrollbar-mini');
    }
  }, []);
  useEffect(() => {
    const calcTranslateX = e => {
      if (e) {
        if (e?.currentTarget?.innerWidth >= 1000) {
          initTranslateX(e?.currentTarget?.innerWidth);
        }
      }
    };
    window.addEventListener('resize', calcTranslateX);
    return () => {
      window.removeEventListener('resize', calcTranslateX);
    };
  }, [rateList]);

  return (
    <>
      <div className="rate-list-container tw-overflow-hidden tw-relative tw-pl-6 md:tw-pl-12 slg:tw-pl-0 tw-max-w-[1920px] tw-mx-auto">
        <div className="tw-overflow-x-hidden" ref={listContainerRef}>
          <div
            style={{
              'scroll-snap-type': 'x mandatory',
              '--tw-translate-x': translateX,
            }}
            className={`tw-rate-list-box tw-transition-all tw-duration-300 tw-flex tw-my-4 tw-overflow-x-scroll tw-gap-x-8 md:tw-my-8 slg:tw-my-16 slg:tw-transform slg:tw-overflow-x-visible ${scrollbarClassName} tw-pr-8 slg:tw-pr-0`}
          >
            {rateList && rateList[rateList.length - 1] && (
              <CustomerRateItem item={rateList[rateList.length - 1]} className="tw-hidden slg:tw-block" />
            )}
            {rateList.map((item, index) => (
              <CustomerRateItem
                key={`rate_item_${index + 1}`}
                item={item}
                active={index === activeIndex}
                canScroll={canScroll}
              />
            ))}
            {rateList && rateList[0] && <CustomerRateItem item={rateList[0]} className="tw-hidden slg:tw-block" />}
          </div>
        </div>

        <div className="tw-absolute tw-bottom-0 tw-flex tw-opacity-0 tw-gap-x-2 slg:tw-opacity-100 tw-right-12">
          <div className=" tw-border tw-rounded-50% tw-cursor-pointer tw-p-3" onClick={toPrevious}>
            <div className="tw-w-6">{renderArrow(activeIndex === 0)}</div>
          </div>
          <div className="tw-border tw-rounded-50% tw-cursor-pointer tw-p-3" onClick={toNext}>
            <div className="tw-w-6 tw-rotate-180">{renderArrow(activeIndex === rateList.length - 1)}</div>
          </div>
        </div>
      </div>

      <RateV2 rateType="default" theme="light" />
    </>
  );
};

export default CustomerRateList;
