import React, { useEffect, useState } from 'react';
import {
  collectionStep1,
  collectionStep2,
  collectionStep3,
  collectionCarLogo,
  collectionStepRightBg,
  collectionStepLeftBg,
  collectionStepRightBgSm,
  collectionStepLeftBgSm,
} from 'public/images/index';
import HomePageIntroSectionTitle from 'components/HomePageIntroSectionTitle/HomePageIntroSectionTitle';
const defaultTopFlows = [
  'Get an online quote ',
  'Arrange collection to suit you',
  'Sit back and relax',
  'Our friendly team come to collect your car',
];
const defaultBottomFlows = [
  {
    img: collectionStep1,
    desc: 'We’ll let you know the driver is on his way',
    commentTitle: 'Car.co.uk',
    comment: 'John is on his way to collect your car, eta 09:15am',
  },
  {
    img: collectionStep2,
    desc: 'The driver will check the car, the paperwork and all details.',
    commentTitle: 'Car.co.uk',
    comment: 'John has arrived to collect your car',
  },
  {
    img: collectionStep3,
    commentTitle: 'Car.co.uk',
    comment: 'We’ve just made payment of £2,816!',
  },
];
const StepClassNames =
  'tw-absolute tw-border-4 tw-border-white tw-rounded-full tw-w-14 tw-h-14  tw-text-2xl tw-leading-14 tw-bg-primary-500 tw-text-white tw-z-10 tw-left-0 tw-top-0 tw--translate-x-5 tw--translate-y-25';
const indexClassNames = 'tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2';
const lineClassNames = 'left-line tw-bg-white tw--mx-px';
const bottomStepItemClassNames =
  'tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-text-center';
const topStepItemClassNames =
  'tw-absolute tw-top-1/2 tw-left-6 tw-right-6 tw-transform tw-break-words tw--translate-y-1/2 tw-text-center';
const ArrowSvg = ({ className }) => (
  <svg className={className} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="endpoints">
      <path
        id="arrow"
        d="M24.2252 14.9096C25.6313 14.2069 26.3344 13.8556 26.3344 13.2993C26.3344 12.7429 25.6313 12.3916 24.2252 11.6889L2.96834 1.06664C2.1421 0.653757 1.72897 0.447314 1.42318 0.510466C1.16488 0.56381 0.942955 0.727625 0.815979 0.958672C0.665654 1.2322 0.741587 1.68754 0.893455 2.59822C1.56555 6.62849 1.9016 8.64363 2.0335 10.671C2.14737 12.4213 2.14737 14.1772 2.0335 15.9276C1.9016 17.9549 1.56555 19.97 0.893455 24.0003C0.741587 24.911 0.665654 25.3663 0.815979 25.6399C0.942955 25.8709 1.16488 26.0347 1.42318 26.0881C1.72897 26.1512 2.1421 25.9448 2.96835 25.5319L24.2252 14.9096Z"
        fill="white"
      />
    </g>
  </svg>
);
const EndpointSvg = ({ className }) => (
  <svg className={className} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="endpoints">
      <circle id="bullet" cx="13.468" cy="13.2993" r="8.53333" stroke="white" strokeWidth="8.53333" />
    </g>
  </svg>
);
const renderTopFlow = (vertical = true, topFlows = defaultTopFlows) => {
  return vertical ? (
    <div className="top-flow-container tw-flex tw-items-center">
      {topFlows.map((item, index) => (
        <>
          <div
            key={`top_vertical_step_${index + 1}`}
            className="flow-item tw-rounded-full tw-relative tw-border-4 tw-border-white 2xl:tw-w-52 tw-w-40"
          >
            <div className="tw-w-full tw-pt-[100%]">
              <div className="tw-w-14">
                <div className={StepClassNames}>
                  <div className={indexClassNames}>{index + 1}</div>
                </div>
              </div>

              <div className={topStepItemClassNames}>{item}</div>
            </div>
          </div>
          {index + 1 !== topFlows.length && (
            <div key={`top_vertical_connect_${index + 1}`} className="tw-flex tw-items-center tw-flex-1">
              <EndpointSvg />
              <hr className={`${lineClassNames} tw-flex-1 tw-h-1.5`} />
              <ArrowSvg />
            </div>
          )}
        </>
      ))}
    </div>
  ) : (
    <div className="top-flow-container tw-flex tw-flex-col tw-items-center">
      {topFlows.map((item, index) => (
        <>
          <div
            key={`top_step_${index + 1}`}
            className="flow-item tw-rounded-full tw-relative tw-border-4 tw-border-white tw-w-52"
          >
            <div className="tw-w-full tw-pt-[100%]">
              <div className="tw-w-14">
                <div className={StepClassNames}>
                  <div className={indexClassNames}>{index + 1}</div>
                </div>
              </div>

              <div className={topStepItemClassNames}>{item}</div>
            </div>
          </div>
          <div key={`top_connect_${index + 1}`} className="tw-flex tw-items-center tw-flex-col">
            <EndpointSvg />
            <hr className={`${lineClassNames} tw-w-1.5 tw-h-28`} />
            <ArrowSvg className="tw-transform tw-rotate-90" />
          </div>
        </>
      ))}
    </div>
  );
};
const renderBottomFlow = (vertical = true, bottomFlows = defaultBottomFlows) => {
  return vertical ? (
    <div className="top-flow-container tw-flex tw-items-center tw-relative">
      {bottomFlows.map((item, index) => (
        <>
          <div
            key={`bottom_vertical_step_${index + 1}`}
            className="flow-item tw-rounded-full tw-relative tw-border-4 tw-border-white 2xl:tw-w-52 tw-w-40"
          >
            <div className="tw-w-full tw-pt-[100%]">
              <div className={StepClassNames}>
                <div className={indexClassNames}>{index + 1}</div>
              </div>
              <img className={bottomStepItemClassNames} src={item.img} alt="step" />
              <div
                className={`comment tw-absolute tw-top-2/3 tw-left-1/2 tw-transform tw--translate-x-1/2
                tw-w-52 2xl:tw-w-72 2xl:tw--translate-x-0
                tw-rounded-xl tw-bg-white tw-bg-opacity-20 tw-backdrop-filter tw-backdrop-blur-2xl tw-flex tw-items-center tw-p-2`}
              >
                <img src={collectionCarLogo} alt="" />
                <div className="message tw-ml-3">
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <div className="tw-text-base tw-text-semibold">{item.commentTitle}</div>
                    <div className="time tw-text-sm tw-opacity-50 ">Now</div>
                  </div>
                  <div className="desc tw-text-xs tw-opacity-80">{item.comment}</div>
                </div>
              </div>
            </div>
          </div>
          {index + 1 !== bottomFlows.length && (
            <div key={`bottom_vertical_connect_${index + 1}`} className="tw-flex tw-items-center tw-flex-1 tw-relative">
              <EndpointSvg />
              <hr className={`${lineClassNames} tw-flex-1 tw-h-1.5`} />
              <ArrowSvg />
              <div className="desc tw-absolute tw-bottom-2 tw-left-7 tw-right-7 tw-text-center tw-font-normal tw-transform tw--translate-y-1/2">
                {item.desc}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  ) : (
    <div className="top-flow-container tw-flex tw-flex-col tw-items-center">
      {bottomFlows.map((item, index) => (
        <div key={`bottom_step_${index + 1}`} className="tw-flex tw-flex-col">
          <div className="flow-item tw-rounded-full tw-relative tw-border-4 tw-border-white tw-w-52">
            <div className="tw-w-full tw-pt-[100%]">
              <div className={StepClassNames}>
                <div className={indexClassNames}>{index + 1}</div>
              </div>
              <img className={bottomStepItemClassNames} src={item.img} alt="step" />
              <div className="comment tw-absolute tw-top-2/3 tw-left-1/2  tw-transform tw--translate-x-1/2 ssm:tw-translate-x-0 tw-w-72 tw-rounded-xl tw-bg-white tw-bg-opacity-20 tw-backdrop-filter tw-backdrop-blur-2xl tw-flex tw-items-center tw-p-2">
                <img src={collectionCarLogo} alt="" />
                <div className="message tw-ml-3">
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <div className="tw-text-base tw-text-semibold">{item.commentTitle}</div>
                    <div className="time tw-text-sm tw-opacity-50 ">Now</div>
                  </div>
                  <div className="desc tw-text-xs tw-opacity-80">{item.comment}</div>
                </div>
              </div>
            </div>
          </div>
          {index + 1 !== bottomFlows.length && (
            <div className="tw-flex tw-flex-col tw-items-center tw-relative">
              <EndpointSvg />
              <hr className={`${lineClassNames} tw-w-1.5 tw-h-28`} />
              <ArrowSvg className="tw-transform tw-rotate-90" />
              <div
                className={`desc tw-text-center tw-absolute tw-top-1/2 ssm:tw-left-8 tw-left-1/2 tw-transform tw--translate-y-1/2 tw--translate-x-1/2 ssm:tw-translate-x-0 tw-w-full tw-z-20 tw-bg-primary-500
              ${index % 2 ? 'ssm:tw--translate-x-3/4' : 'ssm:tw-translate-x-1/2'} `}
              >
                {item.desc}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
const renderCenterArrow = () => {
  return (
    <div className="center-arrow tw-flex tw-justify-end 2xl:tw-px-28 tw-px-20 tw-overflow-x-hidden">
      <div className="tw-w-1/2">
        <div className="tw-flex tw-flex-col tw-items-end">
          <EndpointSvg className="tw-transform tw-translate-x-1/2" />
          <div
            className="tw-border-r-4 tw-border-b-4 tw-border-white tw-h-12 tw-rounded-br-3xl"
            style={{ width: 'calc(100% - 24px)' }}
          ></div>
        </div>
        <div className="tw-transform tw--translate-y-1 tw--translate-x-1">
          <div
            className="tw-border-l-4 tw-border-t-4  tw-border-white tw-h-12 tw-rounded-tl-3xl"
            style={{ width: 'calc(100% - 24px)' }}
          ></div>
          <ArrowSvg className="tw-transform tw-rotate-90 tw--translate-x-1/2" />
        </div>
      </div>
    </div>
  );
};

const HowItWorks = ({ topFlows, bottomFlows, classNames, title: { mainContent, subContent } }) => {
  const VERTICAL = 'vertical';
  const HORIZONTAL = 'horizontal';
  const [layout, setLayout] = useState(VERTICAL);
  useEffect(() => {
    if (document.body.offsetWidth <= 1000) {
      setLayout(HORIZONTAL);
    }
  }, []);
  useEffect(() => {
    const calcDisplayType = e => {
      if (e) {
        if (e?.currentTarget?.innerWidth <= 1000) {
          setLayout(HORIZONTAL);
        } else {
          setLayout(VERTICAL);
        }
      }
    };
    window.addEventListener('resize', calcDisplayType);
    return () => {
      window.removeEventListener('resize', calcDisplayType);
    };
  }, [layout, topFlows, bottomFlows]);

  return (
    <div className="tw-tracking-tight tw-relative">
      <div className={classNames}>
        <HomePageIntroSectionTitle
          title={{
            mainContent,
            subContent,
          }}
          limitWidth={false}
        />
        {renderTopFlow(layout === VERTICAL, topFlows)}
        {layout === VERTICAL && renderCenterArrow()}
        <div className="center-state tw-text-4xl tw-text-center tw-text-bold slg:tw-mb-16 slg:tw-mt-0 tw-my-12">
          On the day of collection
        </div>
        {renderBottomFlow(layout === VERTICAL, bottomFlows)}
      </div>
      {layout === VERTICAL ? (
        <>
          <img
            src={collectionStepRightBg}
            alt="right-bg"
            className="2xl:tw-w-80 slg:tw-w-44 tw-w-32 tw-absolute tw-right-0 tw-top-0"
          />
          <img
            src={collectionStepLeftBg}
            alt="left-bg"
            className="2xl:tw-w-80 xl:tw-w-44 tw-w-32 tw-absolute tw-bottom-0 tw-transform tw--translate-x-1/2 mlg:tw--translate-x-1/4 sxl:tw--translate-x-1/2 1.5xl:tw--translate-x-1/4 3xl:tw-translate-x-0"
          />
        </>
      ) : (
        <>
          <img
            src={collectionStepRightBgSm}
              alt="right-bg"
            className="sm:tw-w-80 tw-w-64 ssm:tw-visible tw-invisible tw-absolute tw-right-0 tw-top-0"
          />
          <img
            src={collectionStepLeftBgSm}
            alt="left-bg"
            className="sm:tw-w-64 tw-w-48 ssm:tw-visible tw-invisible tw-absolute tw-bottom-1/4 sxl:tw-transform tw-translate-y-1/4"
          />
        </>
      )}
    </div>
  );
};

export default HowItWorks;
