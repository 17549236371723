import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { postCodes } from 'api-client/apiClientInstance/postCodes';

import Autosuggest from 'react-autosuggest';

import { reportToBugsnag } from 'lib/bugsnag';

const PostcodeAutocompleteV2 = React.memo(
  ({ value, error, name, id, placeholder, classNames, setFieldValue, setFieldTouched, isUppercaseValue }) => {
    const [suggestions, setSuggestions] = useState([]);
    const { current } = useRef({ timer: null });

    const onSuggestionsFetchRequested = ({ value: val }) => {
      if (current.timer) {
        clearTimeout(current.timer);
      }
      current.timer = setTimeout(async () => {
        try {
          const suggested = await postCodes.autocomplete(val);
          if (suggested?.length) {
            setSuggestions(suggested);
          }
        } catch (err) {
          reportToBugsnag(err, 'onSuggestionsFetchRequested');
        }
      }, 300);
    };

    const onSuggestionsClearRequested = () => setSuggestions([]);

    const getSuggestionValue = suggestion => suggestion;

    const renderSuggestion = suggestion => <div>{suggestion}</div>;

    const shouldRenderSuggestions = val => val.trimStart().length > 3;

    const inputProps = useMemo(() => {
      return {
        placeholder,
        value,
        name,
        id,
        onChange: (e, { newValue }) => setFieldValue(name, isUppercaseValue ? newValue.toUpperCase() : newValue),
        onBlur: () => setFieldTouched(name),
        className: 'tw-form-control',
      };
    }, [placeholder, value, name, id, setFieldValue, setFieldTouched]);

    return (
      <div className={cn({ 'tw-has-error': error }, classNames)}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          inputProps={inputProps}
        />
        <span>{error}</span>
      </div>
    );
  },
);

PostcodeAutocompleteV2.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  classNames: PropTypes.string,
  isUppercaseValue: PropTypes.bool,
};

PostcodeAutocompleteV2.defaultProps = {
  id: '',
  placeholder: '',
  error: '',
  classNames: '',
  isUppercaseValue: false,
};

export default PostcodeAutocompleteV2;
