import React from 'react';
import PropTypes from 'prop-types';

const CustomBaseInputField = ({ field: { name, value, onBlur, onChange }, form, customChangeType, ...props }) => {
  const { setFieldValue } = form;
  const onChangeHandler = e => {
    const fieldValue = e.target.value || '';
    if (customChangeType.type === 'uppercase') {
      setFieldValue(name, fieldValue.toUpperCase());
    } else if (customChangeType.type === 'nonnegative') {
      setFieldValue(name, fieldValue > 0 ? fieldValue : '');
    } else {
      setFieldValue(name, fieldValue);
    }
  };

  const onInput = e => {
    const fieldValue = e.target.value || '';
    if (customChangeType.type === 'nonnegative') {
      e.target.value = /\d+/.test(fieldValue) ? fieldValue : '';
    }
  };

  return (
    <input
      className="tw-form-control"
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={customChangeType ? onChangeHandler : onChange}
      onInput={onInput}
      {...props}
    />
  );
};

CustomBaseInputField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  customChangeType: PropTypes.object,
};

export default CustomBaseInputField;
