import React, { useRef, useEffect } from 'react';
import { quoteMarkerSvg, CircleCheckFilledGreyIcon } from 'public/images/icons/index';

const CustomerRateItem = ({ item, active = false, className, canScroll }) => {
  const itemRef = useRef(null);
  useEffect(() => {
    if (active && canScroll) {
      const itemElement = itemRef.current;
      itemElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [active, item]);
  return (
    <div
      className={`tw-rate-item tw-transition-all tw-duration-300 tw-group slg:tw-py-16 tw-relative tw-min-w-[92%] sm:tw-min-w-[86%] slg:tw-min-w-[40%] xl:tw-min-w-[35%] 5xl:tw-min-w-[32%] tw-p-8 md:tw-p-16 ${className ||
        ''} ${active ? 'active' : ''}`}
      key={item.id}
      ref={itemRef}
      style={{ 'scroll-snap-align': 'start' }}
    >
      <div className="tw-relative tw-transition-all tw-duration-300 tw-rate-item-box group-hover:tw-scale-102">
        <div className="tw-text-2xl tw-font-bold title slg:tw-text-3xl">{item.title}</div>
        <div className="tw-my-6 comment tw-text-grey-500">{item.comment}</div>
        <div className="tw-flex tw-items-end tw-justify-between">
          <div className="user">
            <div className="tw-font-bold tw-text-default md:tw-text-base slg:tw-text-xl">⏤ {item.user}</div>
            <div className="tw-flex tw-items-center tw-text-grey-500">
              <img className="tw-mr-3" src={CircleCheckFilledGreyIcon} alt="verified" /> Verified
            </div>
          </div>
          <div className="tw-rate-img xsMax:tw-min-w-[5rem]">
            <img
              className="tw-h-6 xsMax:tw-h-4 xsMax:tw-mb-[0.3rem]"
              src="https://stage-drupal.car.co.uk/s3fs-public/2020-02/Trustpilot_ratings_5star-RGB.png"
              alt="trust pilot rating logo"
            />
          </div>
        </div>
        <img className="tw-absolute tw-w-20 tw-top-0 tw-right-0 tw-z-[-1]" src={quoteMarkerSvg} alt="item-bg" />
      </div>
    </div>
  );
};

export default CustomerRateItem;
