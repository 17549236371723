import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import HomePageParagraphHtmlContainer from './HomePageParagraphHtmlContainer';
import LaptopRobotGraphic from './LaptopRobotGraphic';
import UserCommentGraphic from './UserCommentGraphic';
import CircleGraphic from '../CircleGraphic/CircleGraphic';

const renderGraphic = (graphicType, graphicData) => {
  switch (graphicType) {
    default:
      return <></>;
    case 'laptopRobot':
      return <LaptopRobotGraphic graphicData={graphicData} />;
    case 'userComment':
      return <UserCommentGraphic graphicData={graphicData} />;
    case 'circle':
      return (
        <div className="tw-relative tw-w-full tw-overflow-visible slg:tw-w-7/12 sxl:tw-w-[48%] tw-pt-0 slg:tw-pt-[20%] sxl:tw-pt-[14%]">
          <CircleGraphic />
        </div>
      );
    case 'text':
      return <HomePageParagraphHtmlContainer paragraphTextData={graphicData} />;
  }
};

export const HomePageParagraph = ({ paragraphData }) => {
  const { graphicType, graphicData, textData, classNames } = paragraphData;
  return paragraphData ? (
    <section
      className={`tw-home-page-paragraph tw-w-full tw-py-6 md:tw-py-12 tw-overflow-hidden tw-bg-grey-light ${classNames ||
        ''}`}
    >
      <div className="tw-container tw-px-6 tw-pt-6 tw-mx-auto md:tw-pt-12 slg:tw-pt-16 sm:tw-px-0">
        <section
          className={cn('tw-flex tw-flex-wrap slg:tw-flex-nowrap tw-gap-12', {
            '!tw-gap-0 slg:!tw-gap-12 tw--mt-4 tw-mb-8 slg:tw--mt-12 slg:tw--mb-8 sxl:tw-mb-8 xl:tw--mb-8': graphicType === 'text',
          })}
        >
          <HomePageParagraphHtmlContainer paragraphTextData={textData} />
          {renderGraphic(graphicType, graphicData)}
        </section>
      </div>
    </section>
  ) : (
    <></>
  );
};

HomePageParagraph.propTypes = {
  paragraphData: PropTypes.shape({
    classNames: PropTypes.string,
    textData: PropTypes.object,
    graphicData: PropTypes.object,
    graphicType: PropTypes.oneOf(['laptopRobot', 'userComment', 'circle', 'text']),
  }),
};

HomePageParagraph.defaultProps = {
  paragraphData: null,
};

export default HomePageParagraph;
