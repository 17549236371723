import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { mobileAndTabletCheck } from 'helpers/mobileAndTabletCheck';
import ChevronIcon from '../CommonFAQs/ChevronIcon';

const BrandBar = ({ brandBarIconList }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [translateX, setTranslateX] = useState('0');
  const [offsetTranslateX, setOffsetTranslateX] = useState(12.5);
  const [scrollBarClass, setScrollBarClass] = useState('tw-scrollbar-hidden');
  const [minOffset, setMinOffset] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  const toPrevious = () => {
    if (activeIndex > 0) {
      const tempIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;
      setActiveIndex(tempIndex);
      let tempOffset = tempIndex * offsetTranslateX * -1;
      tempOffset = tempOffset < minOffset ? minOffset : tempOffset;
      setTranslateX(`${tempOffset}%`);
    }
  };
  const toNext = () => {
    if (activeIndex < brandBarIconList.length - 1) {
      const tempIndex = activeIndex + 1 > maxIndex ? maxIndex : activeIndex + 1;
      setActiveIndex(tempIndex);
      let tempOffset = tempIndex * offsetTranslateX * -1;
      tempOffset = tempOffset < minOffset ? minOffset : tempOffset;
      setTranslateX(`${tempOffset}%`);
    }
  };

  useEffect(() => {
    if (brandBarIconList) {
      setOffsetTranslateX(100 / brandBarIconList?.length);
    }
    const resetOffset = () => {
      setTranslateX(0);
      let tempMaxIndex = 0;

      if (document.body.offsetWidth > 1000) {
        tempMaxIndex = 0;
      } else if (document.body.offsetWidth > 768) {
        tempMaxIndex = 3;
      } else if (document.body.offsetWidth > 470) {
        tempMaxIndex = 4;
      } else if (document.body.offsetWidth < 470) {
        tempMaxIndex = 6;
      }

      setMaxIndex(tempMaxIndex);
      if (tempMaxIndex > 0) {
        setMinOffset(tempMaxIndex * offsetTranslateX * -1 + offsetTranslateX / 2);
      }

      const isMobileOrTable = mobileAndTabletCheck();
      setScrollBarClass(isMobileOrTable ? '' : 'tw-scrollbar-hidden');
    };
    resetOffset();
    window.addEventListener('resize', resetOffset);
    return () => {
      window.removeEventListener('resize', resetOffset);
    };
  }, [brandBarIconList]);

  const scrollButtonClass = `tw-w-8 tw-flex tw-items-center tw-justify-center slg:!tw-hidden tw-absolute tw-top-0 tw-h-full tw-transition-all hover:tw-bg-opacity-[0.15] tw-cursor-pointer tw-bg-white tw-bg-opacity-10 tw-shadow-md tw-backdrop-filter tw-backdrop-blur-xl tw-text-white`;

  return brandBarIconList ? (
    <div className="tw-relative tw-w-full tw-h-20">
      <div
        className={`tw-w-full tw-relative tw-h-full tw-overflow-x-auto tw-bg-blue-dark ${scrollBarClass}`}
        style={{
          'scroll-snap-type': 'x mandatory',
        }}
      >
        <div
          className="tw-flex tw-items-center tw-justify-around tw-w-[320%] tw-transition xs:tw-w-[175%] md:tw-w-[145%] slg:tw-w-full tw-h-full tw-select-none"
          style={{
            transform: `translateX(${translateX})`,
          }}
        >
          {brandBarIconList &&
            brandBarIconList.map((item, index) => {
              return (
                <div
                  id={`brandItem${index}`}
                  className="tw-flex tw-w-[12.5%] tw-items-center tw-justify-center tw-h-full"
                  style={{ 'scroll-snap-align': 'start' }}
                >
                  {item?.contentStyle === 'img' && (
                    <img
                      key={item.key}
                      className={`tw-h-1/5 tw-w-auto tw-mx-auto ${item.classNames}`}
                      alt="Brand Icon"
                      src={item.icon}
                    />
                  )}
                  {item?.contentStyle === 'text' && (
                    <span
                      key={item.key}
                      className={`tw-text-sm tw-text-white tw-font-bold tw-w-auto tw-mx-auto tw-font-KumbhSans ${item.classNames}`}
                    >
                      {item.content}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div
        className={`tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-group ${
          scrollBarClass === 'tw-scrollbar-hidden' ? '' : '!tw-hidden'
        }`}
      >
        <div className="tw-h-0 group-hover:tw-h-full">
          <div
            className={`tw-opacity-0 tw-transition-all tw-duration-300 group-hover:tw-opacity-100 ${scrollButtonClass}`}
            onClick={toPrevious}
          >
            <ChevronIcon iconStyle="left" color="#D9D9D9" />
          </div>
          <div
            className={`tw-right-0 tw-opacity-0 tw-transition-all tw-duration-300 group-hover:tw-opacity-100 ${scrollButtonClass}`}
            onClick={toNext}
          >
            <ChevronIcon iconStyle="right" color="#D9D9D9" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

BrandBar.propTypes = {
  brandBarIconList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      classNames: PropTypes.string,
      icon: PropTypes.string,
      content: PropTypes.string,
      contentStyle: PropTypes.oneOf(['img', 'text']),
    }),
  ),
};

BrandBar.defaultProps = {
  brandBarIconList: null,
};

export default BrandBar;
