import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getHomePageMediasV2Request } from 'store/actions/homePageV2';
import moment from 'moment';
import ParagraphNavCard from './ParagraphNavCard';
import { isEmpty } from '../../lib/lodashReplace';

const getNumberWithOrdinal = number => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (s[(v - 20) % 10] || s[v] || s[0]);
};
const ParagraphNavCardList = ({ navItems, cardStyle, queryMedia, getHomePageMediasRequest: getMedia, medias }) => {
  const navList = useMemo(() => {
    if (queryMedia) {
      return medias.slice(0, 8).map(item => {
        const date = moment(item.timestamp * 1000);
        const dateLabelStr = `${getNumberWithOrdinal(date.date())} ${date.format('MMMM')} ${date.year()}`;
        return {
          bgUrl: item?.imageMobile,
          title: item?.title,
          date: dateLabelStr,
          link: item?.url,
        };
      });
    }
    return [...navItems];
  }, [medias, navItems]);

  useEffect(() => {
    if ((queryMedia && isEmpty(medias)) || [...medias].length === 0) {
      getMedia();
    }
  }, [medias, queryMedia]);

  return (
    <section className="tw-grid tw-grid-cols-1 tw-gap-6 tw-paragraph-nav-box ssm:tw-gap-4 tw-gap-x-6 md:tw-gap-x-16 slg:tw-gap-x-4 ssm:tw-grid-cols-2 slg:tw-grid-cols-4">
      {navList.map((item, index) => {
        return <ParagraphNavCard key={`${item.title}_${index + 1}`} cardData={item} cardStyle={cardStyle} />;
      })}
    </section>
  );
};

ParagraphNavCardList.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      bgUrl: PropTypes.string,
      label: PropTypes.string,
      date: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  cardStyle: PropTypes.oneOf(['default', 'withLabel']),
  queryMedia: PropTypes.bool,
};

ParagraphNavCardList.defaultProps = {
  navItems: [],
  cardStyle: 'default',
  queryMedia: false,
};

const mapStateToProps = state => ({
  medias: state.homePageV2.medias,
});

const mapDispatchToProps = dispatch => ({
  getHomePageMediasRequest: data => dispatch(getHomePageMediasV2Request(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParagraphNavCardList);
