import React from 'react';
import { hmpEllipse1, hmpEllipse2, hmpEllipse3 } from 'public/images/index';
import { CommonLabel } from 'components/CommonLabel/index';
const CircleGraphic = () => {
  const circleBlockCommonClassName = 'circle tw-absolute tw-transform lg:tw-max-w-1/2 tw-max-w-[40%] tw-left-1/2';
  const customLabelClasses = '!tw-leading-none !tw-break-normal !tw-tracking-tighter';
  return (
    <div className="circle-graphic-container tw-relative tw-pb-[75%] slg:tw-pb-0 slg:tw-h-auto slg:tw-min-h-[600px]">
      <div className={`${circleBlockCommonClassName} tw-transform tw--translate-x-1/2 tw-z-10`}>
        <CommonLabel
          content="Redistribute food to support community groups"
          bgColor="black"
          customLabelClasses={customLabelClasses}
          maxWidth="normal"
          classNames="tw-absolute tw-left-0 tw-top-[5%] tw-transform tw--translate-x-2/3"
        />
        <img className="top-circle" src={hmpEllipse1} alt="ellipse1" />
        <CommonLabel
          content="Provide employment training in HMP Academies"
          bgColor="black"
          customLabelClasses={customLabelClasses}
          maxWidth="normal"
          classNames="tw-absolute tw-top-[40%] tw-right-0 tw-transform tw-translate-x-2/3"
        />
      </div>
      <div className={`${circleBlockCommonClassName} tw-transform tw-translate-x-[-87.5%] tw-translate-y-3/4`}>
        <CommonLabel
          content="Create supported living services for people without homes"
          bgColor="black"
          customLabelClasses={customLabelClasses}
          maxWidth="normal"
          classNames="tw-absolute tw-top-[67%] tw-transform tw--translate-x-1/4"
        />
        <img className="left-circle" src={hmpEllipse2} alt="ellipse2" />
      </div>
      <div className={`${circleBlockCommonClassName} tw-z-20 tw-translate-x-[-12.5%] tw-translate-y-3/4`}>
        <img className="right-circle" src={hmpEllipse3} alt="ellipse3" />
        <CommonLabel
          content="Significantly reduce crime and reoffending rates"
          bgColor="black"
          customLabelClasses={customLabelClasses}
          maxWidth="normal"
          classNames="tw-absolute tw-top-[72%] tw-right-0 tw-transform tw-translate-x-1/4 slg:tw-translate-x-1/7"
        />
      </div>
    </div>
  );
};

export default CircleGraphic;
