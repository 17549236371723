import React from 'react';
import PropTypes from 'prop-types';
import NavButtonWithIcon from './NavButtonWithIcon';

const NavButtonWithIconList = ({ navItems, buttonStyle }) => {
  return (
    <>
      <section className={`tw-px-0 ${buttonStyle === 'long' ? '' : 'md:tw-px-10 xl:tw-px-12'}`}>
        <div
          className={`tw-grid tw-h-full ${
            buttonStyle === 'long'
              ? 'tw-grid-cols-1 slg:tw-grid-cols-2 tw-gap-4'
              : 'tw-gap-6 ssm:tw-gap-4 xl:!tw-gap-x-2 tw-grid-cols-2 ssm:tw-grid-cols-3 slg:tw-grid-cols-4 xl:tw-grid-cols-5'
          }`}
        >
          {navItems.map((data, index) => {
            return <NavButtonWithIcon key={`${data.title}_${index + 1}`} buttonStyle={buttonStyle} buttonData={data} />;
          })}
        </div>
      </section>
    </>
  );
};

NavButtonWithIconList.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      subTitle: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  buttonStyle: PropTypes.oneOf(['long', 'short']),
};

NavButtonWithIconList.defaultProps = {
  navItems: [],
  buttonStyle: 'long',
};

export default NavButtonWithIconList;
