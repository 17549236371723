import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CommonLabel = ({
  content,
  subContent,
  icon,
  bgColor,
  maxWidth,
  boldFont,
  labelStyle,
  labelSize,
  roundCornerPosition,
  classNames,
  breakWord,
  withTail,
  customLabelClasses,
}) => {
  let renderLabelResult;
  let maxWidthClass;
  let bgColorClass;
  let fontColorClass;
  let subFontColorClass;
  let borderRadiusClass;

  switch (maxWidth) {
    case 'none':
    default:
      maxWidthClass = 'tw-max-w-none';
      break;
    case 'long':
      maxWidthClass = 'tw-max-w-xxs xs:tw-max-w-2xxs ssm:tw-max-w-3xxs sxl:tw-max-w-xs';
      break;
    case 'short':
      maxWidthClass = 'tw-max-w-xxs ssm:tw-max-w-2xxs';
      break;
    case 'normal':
      maxWidthClass = 'tw-max-w-xxs xs:tw-max-w-2xxs ssm:tw-max-w-3xxs';
      break;
  }

  switch (bgColor) {
    default:
    case 'green':
      bgColorClass = 'tw-bg-green-base';
      fontColorClass = 'tw-text-white';
      break;
    case 'white':
      bgColorClass = 'tw-bg-white';
      fontColorClass = 'tw-text-black';
      subFontColorClass = 'tw-text-gray-400';
      break;
    case 'lightBlue':
      bgColorClass = 'tw-bg-blue-light';
      fontColorClass = 'tw-text-grey-darkText';
      break;
    case 'black':
      bgColorClass = 'tw-bg-black';
      fontColorClass = 'tw-text-white';
      break;
  }

  switch (roundCornerPosition) {
    default:
      borderRadiusClass = 'tw-rounded-full';
      break;
    case 'topLeft':
      borderRadiusClass = 'tw-rounded-r-3xl tw-rounded-b-3xl';
      break;
    case 'topRight':
      borderRadiusClass = 'tw-rounded-l-3xl tw-rounded-b-3xl';
      break;
    case 'bottomLeft':
      borderRadiusClass = 'tw-rounded-r-3xl tw-rounded-t-3xl';
      break;
    case 'bottomRight':
      borderRadiusClass = 'tw-rounded-l-3xl tw-rounded-t-3xl';
      break;
  }

  switch (labelStyle) {
    default:
    case 'default':
    case 'withTail':
      renderLabelResult = (
        <div
          className={cn(
            `tw-flex tw-text-left tw-select-none ${borderRadiusClass} ${bgColorClass} ${maxWidthClass} ${classNames}`,
            {
              'tw-font-bold': boldFont,
              'lg:tw-px-5 tw-px-4 tw-py-1 lg:tw-py-2': labelSize === 'default',
              'lg:tw-px-3 tw-px-2 tw-py-2 tw-max-h-8': labelSize === 'small',
            },
          )}
        >
          <span
            className={cn(
              `${fontColorClass} ${customLabelClasses} tw-whitespace-normal tw-overflow-ellipsis tw-z-[2] tw-tracking-tighter xs:tw-tracking-normal`,
              {
                'tw-text-xxs sm:tw-text-xs md:tw-text-default tw-leading-3 sm:tw-leading-5': labelSize === 'default',
                'tw-text-xs tw-leading-none': labelSize === 'small',
                'tw-break-all': !breakWord,
                'tw-break-word': breakWord,
              },
            )}
          >
            {content}
          </span>
          {withTail && (
            <div className="tw-z-[1] tw-bottom-[15%] xxs:tw-bottom-[10%] xs:tw-bottom-[5%] tw--right-1 tw-absolute tw-w-4 tw-h-4 tw-overflow-hidden">
              <div
                className="tw-border-0 tw-block tw-w-[1.8rem] tw-h-[0.8rem] tw-bg-transparent tw-rounded-bl-[50%] tw-rounded-br-[50%] tw-rotate-[50deg]"
                style={{ boxShadow: `-.8rem -.2rem 0 .7rem ${bgColor}` }}
              ></div>
            </div>
          )}
        </div>
      );
      break;
    case 'withIcon':
      renderLabelResult = (
        <div
          className={cn(
            `tw-cursor-pointer tw-select-none tw-transition-all tw-duration-300 tw-shadow-lg tw-py-1 ssm:tw-py-2 tw-flex tw-flex-row tw-justify-between hover:tw-shadow-xl hover:tw-scale-102 ${borderRadiusClass} ${bgColorClass} ${maxWidthClass} ${classNames}`,
            {
              'tw-font-medium ssm:tw-font-bold': boldFont,
            },
          )}
        >
          <div className="tw-flex tw-flex-col tw-justify-between tw-w-full tw-h-full tw-text-left tw-place-content-between">
            <span
              className={`${fontColorClass} tw-pl-2 ssm:tw-pl-5 tw-mt-1 ssm:tw-mt-2 tw-text-xxs ssm:tw-text-xs slg:tw-text-xxs sxl:tw-text-xs tw-tracking-tighter xs:tw-tracking-normal tw-leading-1 ssm:tw-leading-3 tw-whitespace-normal tw-overflow-ellipsis`}
            >
              {content}
            </span>
            {subContent && (
              <span
                className={`${subFontColorClass} tw-px-2 tw-pt-1 xs:tw-pt-2 ssm:tw-px-5 tw-text-xxs ssm:tw-text-xs slg:tw-text-xxs sxl:tw-text-xs tw-leading-5 tw-font-light tw-tracking-tighter xs:tw-tracking-normal tw-whitespace-normal tw-overflow-ellipsis`}
              >
                {subContent}
              </span>
            )}
          </div>

          <div className="tw-w-10 tw-h-10 tw-my-auto tw-mr-2 tw-overflow-hidden ssm:tw-w-14 ssm:tw-h-14 tw-align-self-center">
            {icon && <img className="tw-w-full" alt={subContent} src={icon} />}
          </div>
        </div>
      );
      break;
  }

  return <>{renderLabelResult}</>;
};

CommonLabel.propTypes = {
  content: PropTypes.string,
  subContent: PropTypes.string,
  icon: PropTypes.string,
  maxWidth: PropTypes.oneOf(['none', 'short', 'normal', 'long']),
  classNames: PropTypes.string,
  customLabelClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(['green', 'white', 'black', 'lightBlue']),
  labelStyle: PropTypes.oneOf(['default', 'withIcon']),
  labelSize: PropTypes.oneOf(['default', 'small']),
  roundCornerPosition: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  breakWord: PropTypes.bool,
  boldFont: PropTypes.bool,
  withTail: PropTypes.bool,
};

CommonLabel.defaultProps = {
  content: '',
  subContent: null,
  icon: null,
  maxWidth: 'none',
  bgColor: 'green',
  labelStyle: 'default',
  labelSize: 'default',
  roundCornerPosition: null,
  classNames: '',
  customLabelClasses: '',
  breakWord: false,
  boldFont: false,
  withTail: false,
};

export default CommonLabel;
