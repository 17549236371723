import React from 'react';
import PropTypes from 'prop-types';

const NavButtonWithIcon = ({ buttonData, buttonStyle }) => {
  const { link, icon, title, description, subTitle } = buttonData;

  return (
    <div
      className={`tw-flex tw-items-center tw-select-none ${
        buttonStyle === 'long' ? 'tw-h-28 md:tw-h-24' : 'tw-h-32 md:tw-h-20 xl:tw-h-24'
      }`}
    >
      <a
        href={link}
        className={`tw-flex tw-flex-row tw-items-center tw-w-full tw-h-full tw-duration-300 tw-border tw-border-gray-300 tw-rounded-xl tw-cursor-pointer tw-group hover:tw-bg-gray-50 tw-transaction ${
          buttonStyle === 'long' ? 'tw-flex-nowrap' : 'tw-pt-4 tw-flex-wrap md:tw-flex-nowrap md:tw-py-0'
        }`}
      >
        <div
          className={`tw-overflow-hidden ${
            buttonStyle === 'long'
              ? 'tw-mx-5 tw-h-12 tw-w-12 md:tw-h-9 md:tw-w-9'
              : 'tw-mx-4 tw-h-7 sxl:tw-h-8 tw-w-7 sxl:tw-w-8'
          }`}
        >
          <img className="tw-w-full" alt="Nav Button Icon" src={icon} />
        </div>
        <div
          className={`tw-flex tw-flex-col tw-w-full tw-h-full tw-text-left tw-duration-300 tw-transaction tw-text-black group-hover:tw-text-gray-600 md:tw-mx-0 ${
            buttonStyle === 'long'
              ? 'tw-justify-center tw-place-content-center'
              : 'tw-pt-2 md:tw-pt-0 md:tw-justify-center md:tw-place-content-center tw-mx-4'
          }`}
        >
          {title && (
            <span className="tw-font-bold tw-leading-6 tw-tracking-wide tw-text-xxs md:tw-leading-none slg:tw-pb-1">
              {title}
            </span>
          )}
          <span
            className={`slg:tw-pr-[10px] xl:tw-pr-[5px] tw-overflow-hidden tw-text-default tw-font-semibold tw-leading-5 tw-whitespace-normal xl:tw-text-base tw-bread-words tw-overflow-ellipsis ${
              buttonStyle === 'long' ? '' : 'tw-w-full'
            }`}
          >
            {subTitle}
          </span>
          {description && (
            <span
              className={`tw-pt-2 tw-overflow-hidden tw-text-xs tw-font-light tw-leading-4 tw-text-gray-400 tw-whitespace-normal tw-bread-words tw-overflow-ellipsis ${
                buttonStyle === 'long' ? 'tw-pr-8' : 'tw-pr-[5px]'
              }`}
            >
              {description}
            </span>
          )}
        </div>
      </a>
    </div>
  );
};

NavButtonWithIcon.propTypes = {
  buttonData: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
  }),
  buttonStyle: PropTypes.oneOf(['long', 'short']),
};

NavButtonWithIcon.defaultProps = {
  buttonData: null,
  buttonStyle: 'long',
};

export default NavButtonWithIcon;
