import FooterV3 from './FooterV3';
const menus = {
  companyPages: [
    { uri: '/social-value', title: 'Social value' },
    { uri: '/contact', title: 'Contact us' },
    { uri: '/privacy-policy', title: 'Privacy policy ' },
    { uri: '/cookie-policy', title: 'Cookie policy ' },
    { uri: '/terms', title: 'Website terms' },
  ],
  scrapMyCarPages: [
    { uri: '/scrap-my-car', title: 'Get a scrap car quote' },
    { uri: '/scrap-my-car/locations', title: 'Scrap a car near you' },
    { uri: '/scrap-my-car/how-it-works', title: 'How our scrap car service works' },
    { uri: '/scrap-my-car/manufacturers', title: 'Scrap any make or model' },
    { uri: '/scrap-my-car/faqs', title: 'Scrap my car FAQs' },
  ],
  insurancePages: [
    { uri: '/motor-insurance/car-insurance', title: 'Get a car insurance quote' },
    { uri: '/motor-insurance/specialist-car-insurance', title: 'Specialist car insurance' },
    { uri: '/motor-insurance/manufacturers', title: 'Insurance quotes by manufacture' },
    { uri: '/motor-insurance/brokers', title: 'Car insurance broker network' },
    { uri: '/motor-insurance/faqs', title: 'Car insurance FAQs' },
  ],
  numberPlatesPages: [
    { uri: '/number-plates', title: 'Buy a private number plate' },
    { uri: '/number-plates/irish-number-plates', title: 'Irish number plates' },
    { uri: '/number-plates/dateless-cherished-number-plates', title: 'Dateless number plates' },
    { uri: '/number-plates/suffix-number-plates', title: 'Suffix number plates' },
    { uri: '/number-plates/prefix-number-plates', title: 'Prefix number plates ' },
    { uri: '/number-plates/current-style-registrations', title: 'Current style registrations ' },
    { uri: '/number-plates/cheap-number-plates', title: 'Cheap number plates' },
    { uri: '/number-plates/search-results', title: 'Number plate search' },
    { uri: '/number-plates/faqs', title: 'Number plate FAQs' },
  ],
  quickLinkPages: [
    { uri: '/register/customer', title: 'Create an account' },
    { uri: '/car-valuation', title: 'Free car valuation ' },
    { uri: '/check-mot-history', title: 'MOT history check' },
    { uri: '/media/news', title: 'Industry news' },
    { uri: '/media/blogs', title: 'Blogs' },
    { uri: '/media/guides', title: 'Guides' },
    { uri: '/media/reviews', title: 'Reviews' },
    { uri: '/media/videos', title: 'Videos' },
  ],
  financePages: [
    { uri: '/car-finance', title: 'Get a car finance quote' },
    { uri: '/car-finance/car-finance-calculator', title: 'Car finance calculator' },
    { uri: '/car-finance/hire-purchase-hp', title: 'Hire purchase (HP)' },
    { uri: '/car-finance/personal-contract-plan-pcp', title: 'Personal contract plan (PCP)' },
    { uri: '/car-finance/personal-loan', title: 'Personal loan' },
    { uri: '/car-finance/fixed-sum-loan', title: 'Fixed sum loan' },
    { uri: '/car-finance/conditional-sale', title: 'Conditional sale' },
    { uri: '/car-finance/manufacturers', title: 'Finance quotes by manufacturer' },
    { uri: '/car-finance/faqs', title: 'Car finance FAQs' },
  ],
  warrantyPages: [
    { uri: '/car-warranties', title: 'Car warranty quote' },
    { uri: '/car-warranties/whats-covered', title: "What's covered" },
    { uri: '/car-warranties/why-choose-us', title: 'Why purchase from us?' },
    { uri: '/car-warranties/manufacturers', title: 'Warranties by manufacturer ' },
    { uri: '/car-warranties/levels-of-cover', title: 'Levels of cover' },
    { uri: '/car-warranties/glossary', title: 'Glossary ' },
    { uri: '/car-warranties/faqs', title: 'Car warranty FAQs' },
  ],
  vehicleInspectionsPages: [
    { uri: '/vehicle-inspections', title: 'Get a vehicle inspection quote ' },
    { uri: '/vehicle-inspections/how-it-works', title: 'How our inspections work' },
    { uri: '/vehicle-inspections/why-choose-us', title: 'Why choose us' },
    { uri: '/vehicle-inspections/faqs', title: 'Vehicle inspection FAQs' },
  ],
};

export { menus, FooterV3 };
