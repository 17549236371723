import * as Yup from 'yup';
import { isBankName } from 'lib/bankHelper';
import { commonNameField } from '../../user';
import { BANK_SORT_CODE_VALIDATION, BANK_ACCOUNT_NUMBER_VALIDATION } from '../../regexp';

export const paymentMethodValidationSchema = Yup.object().shape({
  payment: Yup.object().required('The payment should not be empty'),
  fullName: commonNameField('full name')
    .test(
      'full name should be account name',
      'Please enter your account name not the bank name',
      value => !isBankName(value?.trimStart())
    )
    .matches(/.+\s+.+/, 'Please enter your full name as per your bank account.')
    .test('min 2 letters', 'The first name or last name must be at least 2 letters', value => {
      const bankNameArr = value?.split(' ') || [];
      if (bankNameArr?.[0]?.length < 2 && bankNameArr?.[1]?.length < 2) {
        return false;
      }
      return true;
    }),
  bankSortCode: Yup.string()
    .matches(BANK_SORT_CODE_VALIDATION, 'The bank sort code entered in incorrect')
    .required('The bank sort code should not be empty'),
  bankAccountNumber: Yup.string()
    .matches(BANK_ACCOUNT_NUMBER_VALIDATION, 'The bank account number entered in incorrect')
    .required('The bank account number should not be empty'),
  referralCode: Yup.string()
    .max(8, 'The referral code entered too long(8)'),
});

export const getPaymentErrorMsg = errors => {
  if (JSON.stringify(errors) !== {}) {
    if (errors.fullName) return errors.fullName;
    if (errors.bankSortCode) return errors.bankSortCode;
    if (errors.bankAccountNumber) return errors.bankAccountNumber;
  }
  return '';
};
