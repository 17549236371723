import React from 'react';
import PropTypes from 'prop-types';

const AiIcon = ({ aiIconData }) => {
  return aiIconData ? (
    <div className={`tw-mx-auto tw-max-w-[12rem] tw-text-left ${aiIconData?.classNames}`}>
      <div className="tw-grid tw-items-center tw-grid-cols-5 tw-gap-1">
        <img className="tw-w-8 tw-h-auto tw-overflow-hidden" alt="AI TECHNOLOGY" src={aiIconData?.icon} />

        <div
          className="tw-col-span-4 tw-font-light tw-leading-4 tw-text-ssm tw-text-green-base"
          dangerouslySetInnerHTML={{ __html: aiIconData?.textHtml }}
        ></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

AiIcon.propTypes = {
  aiIconData: PropTypes.shape({
    classNames: PropTypes.string,
    icon: PropTypes.string,
    textHtml: PropTypes.string,
  }),
};

AiIcon.defaultProps = {
  aiIconData: null,
};

export default AiIcon;
