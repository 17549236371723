import {
  CircleCheckFilledWhiteIcon,
  iconNavCarModel,
  iconNavCarCrash,
  iconNavCarTurbine,
  iconNavCoin,
  iconNavFile,
  iconNavShoppingCart,
  iconNavBadgeCC,
  iconNavCarCrane,
  iconNavHistory,
  iconNavNews,
  iconNavCategoryA,
  iconNavCategoryB,
  iconNavCategoryN,
  iconNavCategoryS,
  iconArrow1,
  iconArrow2,
  iconArrow3,
  iconArrowWhite,
  iconRobot,
  iconAvatar1,
  iconAvatar2,
  iconAvatar3,
  iconAvatar4,
  iconAvatar5,
  iconAI,
  iconBrandDailyMail,
  iconBrandDailyStar,
  iconBrandEcho,
  iconBrandExpress,
  iconBrandManchesterNews,
  iconBrandMetro,
  iconBrandMirror,
} from 'public/images/icons/index';

const quoteTopBannerData = {
  bgItems: [
    {
      key: '1',
      src: 'https://stage-drupal.car.co.uk/s3fs-public/2023-11/quote-banner-circle-1.png',
      classNames:
        'tw-absolute tw-bottom-1 tw-hidden sm:tw-block slg:tw-bottom-[-2.5rem] tw-left-[-13rem] slg:tw-left-[-17rem] sxl:tw-left-[-9rem] tw-w-[20rem] slg:tw-w-[30rem] tw-h-auto',
    },
    {
      key: '2',
      src: 'https://stage-drupal.car.co.uk/s3fs-public/2023-11/quote-banner-circle-2.png',
      classNames:
        'tw-absolute tw-top-[30%] slg:tw-top-[15%] tw-hidden sm:tw-block sm:tw-right-[-5rem] slg:tw-right-[-3rem] sxl:tw-right-0 tw-h-auto tw-w-[10rem] slg:tw-w-[15rem]',
    },
  ],
  pointTextData: {
    icon: CircleCheckFilledWhiteIcon,
    textList: [
      {
        subTitle: 'No price haggling',
      },
      {
        subTitle: 'Immediate payment',
      },
      {
        subTitle: 'Same or next days collection',
      },
    ],
  },
  brandBarData: {
    iconList: [
      {
        key: '1',
        contentStyle: 'text',
        content: 'Featured In',
      },
      {
        key: '2',
        contentStyle: 'img',
        icon: iconBrandDailyMail,
        classNames: '!tw-h-1/4',
      },
      {
        key: '3',
        contentStyle: 'img',
        icon: iconBrandMetro,
      },
      {
        key: '4',
        contentStyle: 'img',
        icon: iconBrandManchesterNews,
      },
      {
        key: '5',
        contentStyle: 'img',
        icon: iconBrandEcho,
      },
      {
        key: '6',
        contentStyle: 'img',
        icon: iconBrandExpress,
      },
      {
        key: '7',
        contentStyle: 'img',
        classNames: '!tw-h-1/4',
        icon: iconBrandMirror,
      },
      {
        key: '8',
        contentStyle: 'img',
        classNames: '!tw-h-1/4',
        icon: iconBrandDailyStar,
      },
    ],
  },
  aiIconData: {
    classNames: 'tw-pt-4 md:tw-pt-6 tw-mx-auto tw-pb-0 md:tw-pb-10',
    icon: iconAI,
    textHtml: 'POWERED BY <br /> <strong>AI TECHNOLOGY</strong>',
  },
};

const quoteInnerBannerData = {
  bgItems: [
    {
      src: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/quote-banner-circle-3.png',
      classNames: 'tw-absolute tw-top-[-6.5rem] tw-right-[-0.5rem] tw-w-[15rem] tw-h-auto',
    },
  ],
  humanData: {
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/quote-banner-people.png',
    textContent: '£4,876',
  },
  arrowData: {
    icon: iconArrowWhite,
    textContent: 'Get the best price now',
  },
  aiIconData: {
    classNames: 'tw-pt-4 md:tw-pt-6 tw-mx-auto tw-pb-0 md:tw-pb-10',
    icon: iconAI,
    textHtml: 'POWERED BY <br /> <strong>AI TECHNOLOGY</strong>',
  },
};

export const homePageQuoteTopBannerData = {
  title: 'Sell your car in under a minute',
  description: 'Dealers, auction houses and scrap yards compete to give you the best price for your old car',
  ...quoteTopBannerData,
};

export const homePageQuoteInnerBannerData = {
  title: 'Sell your car in under a minute',
  description: 'Dealers, salvage agents and scrap yards compete to give you the best price for your old car',
  ...quoteInnerBannerData,
};

export const scrapCarQuoteTopBannerData = {
  title: 'Sell your damaged car and get an instant quote',
  description: '1000’s of dealers & scrap yards compete to give you the best price for your used car!',
  ...quoteTopBannerData,
};

export const scrapCarQuoteInnerBannerData = {
  title: 'Sell your car in under a minute',
  description: 'Dealers, salvage agents and scrap yards compete to give you the best price for your old car',
  ...quoteInnerBannerData,
};

export const pointList = [
  {
    subTitle: 'Instant Quote',
  },
  {
    subTitle: 'Price Guarantee',
  },
  {
    subTitle: 'Fast Collection',
  },
  {
    subTitle: 'Immediate payment',
  },
];

export const paragraphNavList = [
  {
    title: 'What Does Cat D Mean? Cat D Cars Explained',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-4_0.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'What does Cat C car insurance write-off mean?',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-5_0.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'How to Sell Your Cat N Car',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-6.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'What is a Cat S category car mean?',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-7.png',
    link: '/vehicle-inspections',
  },
];

export const paragraphNavListWithLabel = [
  {
    title: 'How to sell your car and get the most for It',
    label: '14 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-1.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'Best places to sell your car online for the most value',
    label: '7 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-2.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'How to price your used car to sell',
    label: '12 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-3.png',
    link: '/vehicle-inspections',
  },
  {
    title: `Best ways to sell a car that won't run`,
    label: '12 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-3.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'How to sell your car for parts',
    label: '14 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-1.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'How to sell your cat N car',
    label: '7 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-2.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'Can you sell a car with no MOT?',
    label: '12 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-3.png',
    link: '/vehicle-inspections',
  },
  {
    title: 'How to price your used car to sell',
    label: '12 min read',
    date: '23rd March 2023',
    bgUrl: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-cover-3.png',
    link: '/vehicle-inspections',
  },
];

export const whySellYourCarList = [
  {
    title: 'Competitive Pricing',
    description: "We ensure you get the best deal for your car's value.",
  },
  {
    title: 'No hidden fees',
    description: 'No surprises—just honest, competitive offers.',
  },
  {
    title: 'Instant  Quotes',
    description:
      "A fair price for your car in under 20 seconds. Time is precious, get back to your day with a quote that's quick and easy.",
  },
  {
    title: 'Convenient Collections',
    description:
      'Your car picked up as soon as today. We work around your schedule, offering same-day and next-day collections.',
  },
  {
    title: 'Immediate Payment',
    description:
      'The funds in your account before our drivers depart. Enjoy the peace of mind that comes with an immediate bank payment.',
  },
  {
    title: 'No Doorstep Haggling',
    description:
      'The price we quote is the price we pay. As long as your car is as described, the quoted price is guaranteed.',
  },
  {
    title: 'Friendly Service',
    description:
      'Meet the team that cares about your experience .Our friendly drivers ensure a pleasant and professional collection process.',
  },
  {
    title: 'Secure Transactions',
    description:
      'Your personal details are encrypted and safe. We protect your information with industry-leading security measures.',
  },
  {
    title: 'Comprehensive Support',
    description:
      "We're here for you every step of the way. From quote to collection, our team provides guidance and updates.",
  },
];

export const ourServiceList = [
  {
    icon: iconNavCarModel,
    title: 'SERVICES',
    subTitle: 'Sell my car',
    link: '/scrap-my-car',
  },
  {
    icon: iconNavCarTurbine,
    title: 'SERVICES',
    subTitle: 'Scrap my car',
    link: '/scrap-my-car',
  },
  {
    icon: iconNavCoin,
    title: 'SERVICES',
    subTitle: 'Car finance',
    link: '/car-finance',
  },
  {
    icon: iconNavFile,
    title: 'SERVICES',
    subTitle: 'Car insurance',
    link: '/motor-insurance/car-insurance',
  },
  {
    icon: iconNavCarCrash,
    title: 'SERVICES',
    subTitle: 'Car warranties',
    link: '/car-warranties',
  },
  {
    icon: iconNavBadgeCC,
    title: 'SERVICES',
    subTitle: 'Private number plates',
    link: '/number-plates',
  },
  {
    icon: iconNavCarCrane,
    title: 'SERVICES',
    subTitle: 'Pre purchase inspections',
    link: '/vehicle-inspections',
  },
  {
    icon: iconNavShoppingCart,
    title: 'SERVICES',
    subTitle: 'Value my car',
    link: '/motor-insurance/specialist-car-insurance',
  },
  {
    icon: iconNavHistory,
    title: 'SERVICES',
    subTitle: 'Check MOT history',
    link: '/motor-insurance/specialist-car-insurance',
  },
  {
    icon: iconNavNews,
    title: 'SERVICES',
    subTitle: 'Car news & reviews',
    link: '/motor-insurance/faqs',
  },
];

export const whatIsADamagedCarList = [
  {
    icon: iconNavCategoryA,
    subTitle: 'Category A',
    description: 'Scrap – complete vehicle crushed without any components being removed.',
    link: '/media/guides/vehicle-auctions/salvage-auction-insurance-write-off-categories-explained',
  },
  {
    icon: iconNavCategoryB,
    subTitle: 'Category B',
    description: 'Scrap – complete vehicle crushed without any components being removed.',
    link: '/media/guides/vehicle-auctions/salvage-auction-insurance-write-off-categories-explained',
  },
  {
    icon: iconNavCategoryS,
    subTitle: 'Category S',
    description: 'The car is structurally damaged but repairable.',
    link: '/media/guides/vehicle-auctions/salvage-auction-insurance-write-off-categories-explained',
  },
  {
    icon: iconNavCategoryN,
    subTitle: 'Category N',
    description: 'The car is non-structurally damaged but repairable.',
    link: '/media/guides/vehicle-auctions/salvage-auction-insurance-write-off-categories-explained',
  },
];

export const sellYourUsedCarForTheBestData = {
  graphicType: 'laptopRobot',
  textData: {
    classNames: 'slg:tw-w-5/12',
    labelContent: 'Selling made easy',
    labelColor: 'green',
    titleHtml: '<h2><strong>Selling Your Car Has Never Been Simpler thanks to Car.co.uk</strong></h2>',
    textHtml: `<p>Welcome to the future of car selling with Car.co.uk, where honesty, transparency, and cutting-edge AI technology converge to create an unparalleled customer service experience. Our friendly team is dedicated to providing you with the most competitive pricing and a class-leading service that makes selling your car online a breeze.</p><p>With just a few clicks, you can receive a fair and fast quote, schedule a convenient collection, and enjoy immediate payment without ever leaving your home. We understand that your time and trust are precious, which is why we've streamlined every step to ensure a seamless, transparent, and enjoyable journey from start to finish.</p><p>Sell your car the smart way, where convenience meets reliability, at Car.co.uk.</p>`,
  },
  graphicData: {
    labels: [
      {
        content: 'We match a buyer looking for your specific car',
        boxClassNames: 'tw-absolute tw-left-0 tw-z-10 tw-top-[6%] xs:tw-top-[3%] sxl:tw-top-1/20',
        classNames: `!tw-max-w-[8rem] ssm:!tw-max-w-[12rem] md:tw-max-w-[13rem] slg:!tw-max-w-[12rem] sxl:!tw-max-w-[19rem]`,
        labelColor: 'green',
        labelMaxWidth: 'long',
        arrow: {
          icon: iconArrow2,
          classNames:
            'tw-absolute tw-right-[-50%] xxs:tw-right-[-55%] xs:tw-right-[-75%] ssm:tw-right-[-50%] slg:tw-right-[-45%] sxl:tw-right-[-40%] tw-top-[5%] xxs:tw-top-[3%] sm:tw-top-0 md:tw-top-0 slg:tw-top-1/5 sxl:tw-top-[3%] tw-z-10 tw-w-16 tw-h-16 xs:tw-w-24 xs:tw-h-24 sxl:tw-w-32 sxl:tw-h-32 ',
        },
      },
      {
        content: 'We get the best price in under 20 seconds',
        boxClassNames: 'tw-absolute tw-right-0 tw-z-10 tw-top-1/5 md:tw-top-[22%]',
        classNames: `!tw-max-w-[9rem] sm:!tw-max-w-[10.5rem] md:!tw-max-w-[13rem]`,
        labelColor: 'green',
        labelMaxWidth: 'short',
        arrow: {
          icon: iconArrow1,
          classNames:
            'tw-absolute tw-z-10 tw-top-[-160%] xxs:tw-top-[-150%] xs:tw-top-[-180%] ssm:tw-top-[-220%] sm:tw-top-[-150%] md:tw-top-[-130%] tw-left-1/4 xxs:tw-left-[15%] xs:tw-left-[-10%] ssm:tw-left-[-20%] sm:tw-left-[15%] md:tw-left-0 slg:tw-left-[15%] sxl:tw-left-[-5%] tw-w-16 tw-h-16 xs:tw-w-24 xs:tw-h-24 sxl:tw-w-32 sxl:tw-h-32',
        },
      },
      {
        content: 'Schedule a collection to suit you',
        boxClassNames:
          'tw-absolute tw-z-10 tw-left-0 xxs:tw-left-1/20 tw-top-[30%] xxs:tw-top-[35%] ssm:tw-top-[40%] sm:tw-top-[35%] md:tw-top-[45%] slg:tw-top-[35%] sxl:tw-top-2/5',
        classNames: `!tw-max-w-[8.5rem] sm:!tw-max-w-[10.5rem] md:!tw-max-w-[13rem]`,
        labelColor: 'green',
        labelMaxWidth: 'short',
        arrow: {
          icon: iconArrow2,
          classNames:
            'tw-absolute tw-z-10 tw-top-[-130%] xxs:tw-top-[-160%] xs:tw-top-[-220%] ssm:tw-top-[-280%] sm:tw-top-[-150%] md:tw-top-[-130%] slg:tw-top-[-150%] tw-right-[30%] xxs:tw-right-[40%] xs:tw-right-[10%] ssm:tw-right-[10%] sm:tw-right-1/4 md:tw-right-1/10 slg:tw-right-[20%] sxl:tw-right-[-10%] tw-rotate-[-50deg] tw-w-16 tw-h-16 xs:tw-w-24 xs:tw-h-24 sxl:tw-w-32 sxl:tw-h-32',
        },
      },
      {
        content: 'Immediate payment once your car is collected',
        boxClassNames:
          'tw-absolute tw-right-0 md:tw-right-[5%] tw-z-10 tw-top-[35%] xxs:tw-top-[40%] xs:tw-top-[45%] md:tw-top-[55%] slg:tw-top-[45%] sxl:tw-top-1/2',
        classNames: `!tw-max-w-[10.5rem] sm:!tw-max-w-[12rem] md:!tw-max-w-[15rem]`,
        labelColor: 'green',
        labelMaxWidth: 'normal',
        arrow: {
          icon: iconArrow3,
          classNames:
            'tw-absolute tw-right-0 tw-z-10 tw-top-[-160%] xxs:tw-top-[-200%] xs:tw-top-[-250%] ssm:tw-top-[-250%] sm:tw-top-[-180%] md:tw-top-[-150%] tw-left-0 xs:tw-left-[-10%] ssm:tw-left-0 md:tw-left-1/5 slg:tw-left-1/3 sxl:tw-left-1/5 tw-w-16 tw-h-16 xs:tw-w-24 xs:tw-h-24',
        },
      },
    ],
    bgCircles: [
      {
        classNames:
          'tw-absolute tw-w-48 tw-h-48 xxs:tw-w-56 xxs:tw-h-56 xs:tw-w-72 xs:tw-h-72 md:tw-w-[22rem] md:tw-h-[22rem] slg:tw-w-60 slg:tw-h-60 sxl:tw-w-80 sxl:tw-h-80 tw-top-[28%] xxs:tw-top-[33%] md:tw-top-1/2 slg:tw-top-[33%] sxl:tw-top-[35%] tw-left-1/20 tw-bg-gray-50 tw-border-gray-100 tw-rounded-full tw-border-[2.5rem] xxs:tw-border-[3.5rem] md:tw-border-[4.5rem] slg:tw-border-[3.5rem] sxl:tw-border-[4.5rem]',
      },
      {
        classNames:
          'tw-absolute tw-w-48 tw-h-48 xxs:tw-w-56 xxs:tw-h-56 xxs:tw-w-56 xxs:tw-h-56 xs:tw-w-72 xs:tw-h-72 md:tw-w-[22rem] md:tw-h-[22rem] slg:tw-w-60 slg:tw-h-60 sxl:tw-w-80 sxl:tw-h-80 tw-top-1/20 md:tw-top-[30%] slg:tw-top-1/20 sxl:tw-top-1/10 tw-right-0 tw-bg-gray-50 tw-border-gray-100 tw-rounded-full tw-border-[2.5rem] xxs:tw-border-[3.5rem] md:tw-border-[4.5rem] slg:tw-border-[3.5rem] sxl:tw-border-[4.5rem]',
      },
    ],
    bgImages: [
      {
        classNames:
          'tw-absolute tw-top-1/10 tw-left-[12%] xxs:tw-left-[15%] sm:tw-left-1/5 slg:tw-left-1/4 tw-w-56 xxs:tw-w-64 xs:tw-w-80 sm:tw-w-[60%] slg:tw-w-[55%] tw-h-auto tw-z-[5]',
        src: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-laptop.png',
      },
    ],
    robotLabel: {
      classNames:
        'tw-absolute tw-right-0 tw-z-10 tw-flex tw-gap-2 xs:tw-gap-4 tw-top-1/2 xxs:tw-top-[55%] xs:tw-top-[68%] md:tw-top-[85%] slg:tw-top-[68%] sxl:tw-top-[80%] tw-flex-nowrap',
      labelColor: 'white',
      labelClassNames: '!tw-px-7 xs:tw-px-4 !tw-py-3',
      labelContentClassNames: '!tw-text-default',
      content:
        'Our intelligent systems will find you the best price from garages, dealers, salvage agents and scrap buyers across the UK.',
      icon: iconRobot,
      iconClassNames: 'tw-w-24 tw-h-24 tw-mt-12 xxs:tw-mt-8 xs:tw-mt-4',
    },
  },
};

export const ourCustomersLoveUsData = {
  graphicType: 'userComment',
  classNames: '!tw-bg-white',
  textData: {
    classNames: 'slg:tw-w-5/12 !tw-bg-white',
    labelContent: '1000’s OF HAPPY CUSTOMERS',
    labelColor: 'green',
    titleHtml: '<h2><strong>We Take the Stress Out of Selling Your Car</strong></h2>',
    textHtml: `<h4>At Car.co.uk, we revolutionize the way you sell your car. Gone are the days of inconvenient viewings, unreliable buyers, and uncertain transactions. Our fully digital car buying service is designed to eliminate every hassle traditionally associated with selling your vehicle.</h4>
    <h4>From the comfort of your home, you can secure a sale that's as quick as it is fair. We guarantee the price quoted as long as your car is as described, with no last-minute renegotiations.</h4>
    <h4>Our process is not just hassle-free but also secure, ensuring your personal details are fully protected with our encrypted system. Our team of collection drivers are not just friendly; they're the face of our commitment to making your experience as smooth and enjoyable as possible.</h4>
    <h4>Trust Car.co.uk to handle everything with the utmost care, because we're not just buying cars—we're building relationships.</h4>`,
  },
  graphicData: {
    labels: [
      {
        icon: iconAvatar3,
        content:
          'Would not hesitate to use car.co.uk again. It was such a smooth process from enquiry to collection. Payment was so prompt and in my account before my car was placed on the lorry.',
        subContent: 'Vanessa Davidson',
        boxClassNames:
          'tw-absolute tw-z-10 tw-top-0 400Max:tw-top-[-5%] slg:tw-top-[-5%] tw-right-0 xxs:tw-right-0 md:tw-right-1/20 slg:tw-right-[-5%] sxl:tw-right-1/20',
        classNames: '400Max:!tw-max-w-[9rem]',
        labelColor: 'white',
        labelMaxWidth: 'long',
        labelStyle: 'withIcon',
        roundCornerPosition: 'bottomLeft',
      },
      {
        icon: iconAvatar2,
        content:
          'Simple, straightforward, honest, friendly service. No making an offer and then trying to give less. They paid what they offered, unlike others. Friendly collection and no hassle.',
        subContent: 'Stephen Hill',
        boxClassNames:
          'tw-absolute tw-z-10 tw-top-0 slg:tw-top-0 sxl:tw-top-0 tw-left-0 xxs:tw-left-0 ssm:tw-left-[-2%] slg:tw-left-[-5%] sxl:tw-left-0',
        classNames: '400Max:!tw-max-w-[8rem]',
        labelColor: 'white',
        labelMaxWidth: 'normal',
        labelStyle: 'withIcon',
        roundCornerPosition: 'bottomRight',
      },
      {
        icon: iconAvatar1,
        content:
          'Very easy process from enquiry to collection and paid before the car left the driveway. The price they quoted was the price they paid , no trying to negotiate a lower price on collection as others do. I’d recommend without hesitation.',
        subContent: 'Gordon Thompson',
        boxClassNames:
          'tw-absolute tw-z-10 tw-top-[16%] 400Min:tw-top-1/5 sxl:tw-top-[15%] tw-right-[1%] md:tw-right-0 slg:tw-right-[-5%] sxl:tw-right-0',
        classNames: '400Max:!tw-max-w-[10rem] 400Min:!tw-max-w-[15rem] md:!tw-max-w-[20rem]',
        labelColor: 'white',
        labelMaxWidth: 'normal',
        labelStyle: 'withIcon',
        roundCornerPosition: 'bottomLeft',
      },
      {
        icon: iconAvatar4,
        content: 'On time and polite. Effectively the process was faultless. Would recommend it to anyone.',
        subContent: 'Sukhvinder Kahn',
        boxClassNames:
          'tw-absolute tw-left-0 xxs:tw-left-0 md:tw-left-0 tw-z-10 tw-top-[28%] xxs:tw-top-[35%] xs:tw-top-[40%] slg:tw-top-2/5 sxl:slg:tw-top-[45%]',
        classNames: '400Max:!tw-max-w-[8rem]',
        labelColor: 'white',
        labelMaxWidth: 'long',
        labelStyle: 'withIcon',
        roundCornerPosition: 'topRight',
      },
      {
        icon: iconAvatar5,
        content:
          'Got a quote at 9pm and the car was taken away with the money in my bank before 11am the next morning. Highest price too!',
        subContent: 'Caroline Walters',
        boxClassNames:
          'tw-absolute tw-z-10 tw-top-[38%] xxs:tw-top-[38%] xs:tw-top-[44%] md:tw-top-[40%] tw-right-0 xxs:tw-right-0 md:tw-right-1/20 slg:tw-right-[-5%] sxl:tw-right-1/20 ',
        classNames: '400Max:!tw-max-w-[8rem]',
        labelColor: 'white',
        labelMaxWidth: 'normal',
        labelStyle: 'withIcon',
        roundCornerPosition: 'topLeft',
      },
    ],
    bgItems: {
      classNames:
        'tw-relative tw-w-72 tw-h-72 xxs:tw-w-80 xxs:tw-h-80 xs:tw-w-[25rem] xs:tw-h-[25rem] tw-mx-auto tw-t-40 tw-over',
      rings: [
        {
          key: '1',
          classNames:
            'tw-absolute tw-w-[25rem] tw-h-[25rem] xs:tw-w-[35rem] xs:tw-h-[35rem] tw-left-[-3.5rem] tw-top-[-3.5rem] xxs:tw-left-[-2.5rem] xxs:tw-top-[-2.5rem] xs:tw-left-[-5rem] xs:tw-top-[-5rem] tw-border-gray-50 tw-rounded-full tw-border-2',
        },
        {
          key: '2',
          classNames:
            'tw-absolute tw-w-[35rem] tw-h-[35rem] xs:tw-w-[45rem] xs:tw-h-[45rem] tw-left-[-8.5rem] tw-top-[-8.5rem] xxs:tw-left-[-7.5rem] xxs:tw-top-[-7.5rem] xs:tw-left-[-10rem] xs:tw-top-[-10rem] tw-border-gray-50 tw-rounded-full tw-border-2',
        },
      ],
      bgImages: [
        {
          key: '1',
          classNames:
            'tw-relative tw-w-full tw-h-full tw-left-0 tw-top-0 tw-bg-center tw-bg-no-repeat tw-bg-150% tw-rounded-full tw-overflow-hidden',
          src: 'https://stage-drupal.car.co.uk/s3fs-public/2023-10/paragraph-car.png',
        },
      ],
    },
  },
};

export const changingTheWayBusinessData = {
  graphicType: 'text',
  textData: {
    classNames: 'slg:tw-w-6/12',
    labelContent: null,
    labelColor: 'green',
    titleHtml: '<h2>Changing the way business is done in the &nbsp;<strong>motor industry</strong></h2>',
    textHtml: `
    <h3>
      <strong>With your help, we support a range of charitable projects supporting people across the UK.</strong>
      </h3>
      <p>
      You perhaps don’t connect training programmes, food redistribution, and supported living homes with the motor industry – but at Car.co.uk, these things are closely linked. As we grow, so does our commitment to creating social value   – a unique   way of doing business that sees us provide financial backing for charitable organisations ; each offering support to real people and communities around the country. When you use our helpful tools, part of the revenue you help us to generate will be donated to charity. Our primary partner charity  – Recycling Lives Charity  - is part of the UK’s largest food redistribution network, working with FareShare UK,  – making sure no good food goes to waste.
      </p>
      <p>
      It also runs training programmes for people currently in prison – helping them to move away from crime upon release and bringing reoffending rates down around the country as a result. Although the people who are directly supported by Recycling Lives Charity obviously see a life-changing benefit, the impact of their work spreads much further. It’s no secret that local authorities often simply do not have the resources to meet everyone’s need adequately – but Recycling Lives Charity helps to ease the financial strain that’s put on taxpayer-funded services .
      </p>
      <p>
      To date, Recycling Lives Charity has created more than £40 million in savings for the criminal justice system, local authorities and small charities - a real difference that can be felt by every UK taxpayer.
      </p>`,
  },
  graphicData: {
    classNames: 'slg:tw-w-6/12 slg:tw-pt-[40px]',
    labelContent: null,
    labelColor: 'green',
    titleHtml: null,
    textHtml: `
    <section class="tw-full tw-justify-center xl:tw-flex tw-hidden tw-pt-4 tw-pb-12">
    <a class="tw-button-black" href="/social-value">
      Read more on social value
    </a>
    </section>
    <div class="tw-w-full">
    <img class="w-100 lazyload" data-src="https://stage-drupal.car.co.uk/s3fs-public/img/homePage/social-value-content-img.png" />
    </div>
  <h3><strong>Real people and real numbers</strong></h3>
  <p>
  Last year, Recycling Lives Charity delivered over three million meals for a range of good causes – from breakfast clubs and community cafes to hospices and food banks. It also works in 10+ prisons across the UK, with 84% of participants going on to finding meaningful paid employment after release. And across one year, each individual who moved on from their residential programme completed 85 hours of work-based training to help them secure full-time employment . With your help, we’re proud to make a measurable difference to real peoples’ lives.
  </p>
  <section class="tw-full tw-justify-center xl:tw-hidden tw-flex tw-pt-8">
  <a class="tw-button-black" href="/social-value">
    Read more on social value
  </a>
  </section>`,
  },
};

// common questions
export const questionData = [
  {
    id: 1,
    title: 'How can I sell my car through your platform?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 2,
    title: 'Can I sell a car that is registered in another state?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 3,
    title: 'Is there a fee for listing my car for sale?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 4,
    title: 'Is my personal information secure when selling my car through your platform?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 5,
    title: 'What information do I need to provide when selling my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 6,
    title: 'Do I need to disclose any known issues or repairs when selling my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 7,
    title: 'How long does it take to sell my car on your platform?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 8,
    title: 'Can I sell a car that is currently leased?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 9,
    title: 'Is it necessary to have a clean title to sell my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 10,
    title: 'Can I sell a car with expired registration?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 11,
    title: 'What types of cars can I sell on your platform?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 12,
    title: "Can I sell a car if it's still under warranty?",
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 13,
    title: 'Do you accept cars in any condition?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 14,
    title: 'Are there any specific photos or documents required for the listing?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 15,
    title: 'Can I sell a car that has been involved in an accident?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 16,
    title: 'What happens after I accept an offer for my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 17,
    title: 'Is there a limit on the number of cars I can sell?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 18,
    title: 'How do you determine the value of my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 19,
    title: 'How do I determine the asking price for my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 20,
    title: 'Do I need to meet the buyer in person to complete the sale?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 21,
    title: 'What payment options are available when selling my car?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 22,
    title: 'Can I sell a car that is not currently running?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
  {
    id: 23,
    title: 'Are there any restrictions on the mileage or age of the car I can sell?',
    description:
      'Lorem ipsum dolor sit amet consectetur. Urna pretium varius morbi nulla venenatis scelerisque. Consequat facilisi in quam quis enim nisi nunc pulvinar. Vivamus ac cras aliquam posuere nunc eget varius cras placerat. Risus congue tincidunt augue malesuada risus at cursus. Elit fringilla leo ac euismod sit. Auctor justo semper tortor sed facilisi ullamcorper vel id. Lacinia dignissim posuere enim porta tortor id consequat. Sem mattis ipsum feugiat enim elementum faucibus accumsan. Eget vitae eu eget mi. Tempor arcu congue sit cras habitant neque. Neque iaculis at cursus nibh ut convallis velit.',
  },
];
