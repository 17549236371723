import React from 'react';
import PropTypes from 'prop-types';
import RateV2 from 'components/RateV2/RateV2';
import cn from 'classnames';
import AIIcon from './AiIcon';
import HomePageQuoteForm from './HomePageQuoteForm';

const renderArrow = (arrowData, verticalArrange) => {
  return (
    <div
      className={cn({
        'tw-hidden slg:tw-flex tw-flex-grow tw-col-span-3 tw-ml-0 slg:tw-ml-[5.5rem] sxl:tw-ml-28 2xl:tw-ml-20': !verticalArrange,
        'tw-flex slg:tw-hidden tw-text-xxs md:tw-text-xs tw-leading-none tw-ml-16 xs:tw-ml-4 md:tw-ml-12 tw-mb-48': verticalArrange,
      })}
    >
      <div
        className={cn('tw-flex tw-h-20', {
          'tw-flex-wrap tw-w-24 tw-mx-auto tw-mt-28': !verticalArrange,
          'tw-flex-row-reverse tw-w-48 tw-flex-nowrap': verticalArrange,
        })}
      >
        <span
          className={cn('tw-text-white tw-text-default tw-font-thin', {
            'tw-mt-16 tw--ml-8': verticalArrange,
          })}
        >
          {arrowData?.textContent}
        </span>
        <img
          className={cn({
            'tw-w-28 tw-ml-4 tw--mt-4': !verticalArrange,
            'tw-w-32 tw-scale-y-[-1] tw-rotate-[-30deg] tw-scale-100 md:tw-scale-y-[-1.5] md:tw-scale-x-150': verticalArrange,
          })}
          alt="Arrow"
          src={arrowData?.icon}
        />
      </div>
    </div>
  );
};

const HomePageInnerQuoteBanner = ({ bannerData }) => {
  const { title, description, humanData, bgItems, aiIconData, arrowData } = bannerData;
  return bannerData ? (
    <div className="tw-inner-quote-banner-container">
      <div className="tw-relative tw-w-full tw-quote-banner-container tw-bg-primary-500">
        <section className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-overflow-hidden">
          <div className="tw-absolute tw-grid tw-items-end tw-justify-start tw-h-full tw-grid-cols-2 tw--left-32 md:tw--left-8 slg:tw--left-48 xl:tw--left-40 2xl:tw--left-24 4xl:tw--left-8">
            <div
              className="tw-relative tw-w-[20rem] tw-h-[25rem] slg:tw-h-full tw-bg-center tw-bg-no-repeat tw-bg-contain tw--bottom-14"
              style={{ 'background-image': `url(${humanData?.bgUrl})` }}
            >
              <button
                type="button"
                className="tw-absolute tw-font-bold tw-right-[-5%] tw-top-1/5 slg:tw-top-1/4 tw-button-with-tail"
              >
                {humanData?.textContent}
              </button>
            </div>
            {renderArrow(arrowData, true)}
          </div>
          <div className="tw-absolute tw-top-0 tw-left-0 tw-hidden tw-w-full tw-h-full ssm:tw-block">
            {bgItems &&
              bgItems.map((item, index) => {
                return (
                  <img key={`inner_bg_${index + 1}`} className={item.classNames} alt="Quote Banner Bg" src={item.src} />
                );
              })}
          </div>
        </section>

        <div className="tw-container tw-relative tw-z-10 tw-px-6 tw-py-12 tw-mx-auto sm:tw-px-0">
          <section className="tw-grid tw-h-full tw-grid-cols-12 ">
            {renderArrow(arrowData, false)}
            <div className="tw-col-span-12 slg:tw-col-span-9 xl:tw-col-span-9 tw-quote-form-box tw-pb-80 slg:tw-pb-4">
              <div className="tw-mx-auto">
                <section className="tw-text-center tw-text-white slg:tw-text-left">
                  <h2>
                    <strong>{title}</strong>
                  </h2>
                  <p className="tw-font-light tw-text-primary-50">{description}</p>
                </section>

                <section className="tw-w-full">
                  <RateV2 rateType="left" theme="dark" />
                </section>

                <section className="tw-w-full tw-pt-8 tw-mx-auto slg:tw-h-28">
                  <HomePageQuoteForm
                    formType="line"
                    placeholderField="ENTER REG"
                    placeholderCode="ENTER YOUR POST CODE"
                    buttonHtml="<strong>GET INSTANT PRICE</strong>"
                  ></HomePageQuoteForm>
                </section>
              </div>
            </div>
          </section>

          <section className="tw-absolute tw-right-0 tw-bottom-8 slg:tw--bottom-4">
            <AIIcon aiIconData={aiIconData} />
          </section>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

HomePageInnerQuoteBanner.propTypes = {
  bannerData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    bgItems: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        classNames: PropTypes.string,
      }),
    ),
    humanData: PropTypes.shape({
      bgUrl: PropTypes.string,
      textContent: PropTypes.string,
    }),
    arrowData: PropTypes.shape({
      icon: PropTypes.string,
      textContent: PropTypes.string,
    }),
    aiIconData: PropTypes.shape({
      classNames: PropTypes.string,
      icon: PropTypes.string,
      textHtml: PropTypes.string,
    }),
  }),
};

HomePageInnerQuoteBanner.defaultProps = {
  bannerData: null,
};

export default HomePageInnerQuoteBanner;
