import React, { useState, useEffect } from 'react';
import { unescapeHTML } from 'lib/helper';
import PropTypes from 'prop-types';
import ChevronIcon from './ChevronIcon';

const Collapse = ({ title, id, isOpen, handleOnClick, description, className }) => {
  const visibleClass = isOpen ? 'tw-open' : '';
  const collapsed = isOpen ? '' : 'collapsed';
  const handleClick = e => {
    e.preventDefault();
    handleOnClick(id);
  };
  return (
    <div
      className={`tw-collapse-wrapper tw-overflow-hidden md:tw-px-8 tw-pt-8 tw-card tw-border-b tw-border-grey-midLight ${visibleClass} ${className}`}
    >
      <h3
        role="presentation"
        onClick={handleClick}
        className="tw-text-primary-mid tw-flex tw-justify-between tw-text-xl ssm:tw-text-base md:tw-text-lg sxl:tw-text-xl"
      >
        {title && (
          <div className={`${collapsed} tw-flex-1 tw-font-bold`} dangerouslySetInnerHTML={{ __html: title }}></div>
        )}
        <ChevronIcon iconStyle={isOpen ? 'up' : 'down'} color="black" />
      </h3>
      <div className="tw-card-area">
        <div className="tw-card-body">
          <div
            className="tw-pb-8 tw-text-grey-500 sxl:tw-text-lg md:tw-text-sm tw-text-xs tw-leading-6 tw-font-normal"
            dangerouslySetInnerHTML={{ __html: unescapeHTML(description) }}
          />
        </div>
      </div>
    </div>
  );
};
const CommonFAQs = ({ questionData, initialIndex = null }) => {
  const initialId = questionData instanceof Array && questionData[initialIndex] && questionData[initialIndex].id;
  const [currentId, setCurrentId] = useState(initialId);
  const handleClick = id => {
    if (currentId === id) {
      setCurrentId(null);
    } else {
      setCurrentId(id);
    }
  };
  const [displayData, setDisplayData] = useState([]);
  const calcDisplayData = offsetWidth => {
    let result = [];
    if (offsetWidth && offsetWidth >= 1000) {
      result = questionData.reduce(
        (newArr, cur, index) => {
          newArr[index % 2].push(cur);
          return newArr;
        },
        [[], []],
      );
    } else {
      result = [questionData];
    }
    setDisplayData(result);
  };
  useEffect(() => {
    calcDisplayData(document.body?.offsetWidth);
  }, []);
  useEffect(() => {
    const updateDisplayData = e => {
      if (e) {
        calcDisplayData(e.target?.innerWidth);
      }
    };
    window.addEventListener('resize', updateDisplayData);
    return () => {
      window.removeEventListener('resize', updateDisplayData);
    };
  }, [questionData]);
  return (
    <div className="common-question-wrapper">
      <div className="tw-grid tw-grid-cols-1 slg:tw-grid-cols-2 tw-gap-x-6 tw-gap-y-4">
        {displayData.map((groupItem, groupIndex) => (
          <div key={`group_${groupIndex + 1}`}>
            {groupItem.map(item => (
              <Collapse key={item.id} {...item} isOpen={item.id === currentId} handleOnClick={handleClick} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
CommonFAQs.prototype = {
  questionData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ processed: PropTypes.string, plain: PropTypes.string }),
      ]),
      description: PropTypes.string,
      url: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  initialIndex: PropTypes.number,
};
export default CommonFAQs;
