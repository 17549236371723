import React from 'react';
import PropTypes from 'prop-types';
const ChevronIcon = ({ iconStyle, classNames, color }) => {
  let styleClassName = 'tw-transform ';

  switch (iconStyle) {
    default:
    case 'up':
      styleClassName += '';
      break;
    case 'down':
      styleClassName += 'tw-rotate-180';
      break;
    case 'left':
      styleClassName += 'tw--rotate-90';
      break;
    case 'right':
      styleClassName += 'tw-rotate-90';
      break;
  }
  return (
    <svg
      className={`${styleClassName} ${classNames}`}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M6 15.2324L12 9.23242L18 15.2324"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
ChevronIcon.prototype = {
  iconStyle: PropTypes.oneOfType(['up', 'left', 'right', 'down']),
  color: PropTypes.oneOfType(['black', 'white']),
  classNames: PropTypes.string,
};
export default ChevronIcon;
