import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  iconPaymentApplePay,
  iconPaymentGooglePay,
  iconPaymentMaestro,
  iconPaymentMasterCard,
  iconPaymentVisa,
  iconMediaLinkedIn,
  iconMediaFacebook,
  iconMediaTwitter,
  iconMediaYoutube,
  iconMediaIns,
} from 'public/images/icons/index';
import MenusInFooter from './MenusInFooter/MenusInFooter';
const FooterV3 = props => {
  const {
    companyPages,
    quickLinkPages,
    numberPlatesPages,
    insurancePages,
    financePages,
    warrantyPages,
    scrapMyCarPages,
    vehicleInspectionsPages,
  } = props;
  const allMenus = useMemo(
    () => [
      {
        title: 'Company',
        pages: companyPages,
      },
      {
        title: 'Scrap my car',
        pages: scrapMyCarPages,
      },
      {
        title: 'Car insurance',
        pages: insurancePages,
      },
      {
        title: 'Number plates',
        pages: numberPlatesPages,
      },
      {
        title: 'Quick Links',
        pages: quickLinkPages,
      },
      {
        title: 'Car finance',
        pages: financePages,
      },
      {
        title: 'Car warranties',
        pages: warrantyPages,
      },
      {
        title: 'Vehicle inspections',
        pages: vehicleInspectionsPages,
      },
    ],
    [
      companyPages,
      quickLinkPages,
      numberPlatesPages,
      insurancePages,
      financePages,
      warrantyPages,
      scrapMyCarPages,
      vehicleInspectionsPages,
    ],
  );
  const mediaList = [
    {
      icon: iconMediaFacebook,
      url: 'https://www.facebook.com/car.co.uk/',
      title: 'Facebook',
    },
    // {
    //   icon: iconMediaTwitter,
    //   url: 'https://twitter.com/car',
    //   title: 'Twitter',
    // },
    {
      icon: iconMediaIns,
      url: 'https://www.instagram.com/car.co.uk/',
      title: 'Instagram',
    },
    {
      icon: iconMediaYoutube,
      url: 'https://www.youtube.com/c/CarCoUk',
      title: 'YouTube',
    },
    {
      icon: iconMediaLinkedIn,
      url: 'https://uk.linkedin.com/company/car',
      title: 'LinkedIn',
    },
  ];
  const paymentList = [
    {
      url: iconPaymentVisa,
      title: 'Visa',
    },
    {
      url: iconPaymentMasterCard,
      title: 'mastercard',
    },
    {
      url: iconPaymentMaestro,
      title: 'maestro',
    },
    {
      url: iconPaymentApplePay,
      title: 'applepay',
    },
    {
      url: iconPaymentGooglePay,
      title: 'googlepay',
    },
  ];
  const [menuList, SetMenuList] = useState([]);
  const [totalColumn, setTotalColumn] = useState(2);
  const calcDisplayMenu = offsetWidth => {
    const target = [[], [], [], []];
    let column = 2;
    if (offsetWidth && offsetWidth >= 1000) {
      column = 4;
    }
    const group = target.length;
    const result = allMenus.reduce((newArr, cur, index) => {
      newArr[index % group].push({ ...cur, id: `group_${index % group}_item_${index}` });
      return newArr;
    }, target);
    setTotalColumn(column);
    SetMenuList(result);
  };
  useEffect(() => {
    calcDisplayMenu(document.body?.offsetWidth);
  }, []);
  useEffect(() => {
    const updateDisplayData = e => {
      if (e) {
        calcDisplayMenu(e.target?.innerWidth);
      }
    };
    window.addEventListener('resize', updateDisplayData);
    return () => {
      window.removeEventListener('resize', updateDisplayData);
    };
  }, [allMenus]);
  return (
    <div className="tw-py-12 tw-text-white slg:tw-py-16 tw-footer tw-bg-primary-500">
      <div className="tw-px-6 ssm:tw-px-12 slg:tw-px-20 tw-max-w-[1920px] tw-mx-auto">
        <div className="tw-logo !tw--m-2">
          <img src="https://www.car.co.uk/images/logo.v2.png" alt="CAR.CO.UK®" />
        </div>
        <div className="tw-pt-8 tw-pb-0 tw-border-b tw-menu-list slg:tw-pt-12 slg:tw-pb-8 tw-border-primary-50">
          <MenusInFooter menuList={menuList} totalColumn={totalColumn} />
        </div>
        <div className="tw-footer-bottom-section">
          <div className="tw-flex tw-text-xs 400Min:tw-text-sm ssm:tw-text-base tw-flex-col 1470Min:tw-flex-row 1.5xl:tw-items-center 1.5xl:tw-justify-between tw-py-10 tw-gap-y-8 tw-gap-x-4 tw-text-white tw-font-semibold">
            <div className="tw-text-center copyright">Car.co.uk, 2023 © All rights reserved.</div>
            <div className="tw-flex tw-flex-col link sm:tw-flex-row 2xl:tw-gap-x-8 tw-gap-x-4 tw-gap-y-6 sm:tw-w-fit sm:tw-mx-auto">
              <div className="tw-flex tw-flex-wrap tw-justify-center tw-mx-auto tw-gap-y-6 tw-gap-x-4 xs:tw-gap-x-8 sm:tw-gap-x-4 2xl:tw-gap-x-8">
                <a href="">Accessibility</a>
                <a href="/privacy-policy">Privacy policy</a>
                <a href="/cookie-policy">Cookie policy</a>
                <a href="/terms" className="tw-block xxs:tw-hidden">
                  Terms
                </a>
                <a href="/" className="tw-block xxs:tw-hidden">
                  Security
                </a>
              </div>
              <div className="tw-hidden tw-mx-auto tw-text-center xxs:tw-flex sm:tw-text-left tw-gap-x-10 xs:tw-gap-x-14 sm:tw-gap-x-4 2xl:tw-gap-x-8 tw-w-fit sm:tw-mx-0">
                <a href="/terms">Terms</a>
                <a href="/">Security</a>
              </div>
            </div>
            <div className="third-party tw-flex tw-flex-col ssm:tw-flex-row ssm:tw-items-center tw-gap-x-4 1.5xl:tw-gap-2 xs:tw-w-fit xs:tw-mx-auto 1.5xl:tw-mx-0">
              <div className="media tw-flex tw-gap-4 1.5xl:tw-gap-2 tw-my-4 tw-mx-auto tw-w-fit">
                {mediaList.map(iconItem => {
                  return (
                    <a
                      target="_blank"
                      href={iconItem.url}
                      key={iconItem.icon}
                      rel="noopener noreferrer"
                      className=" tw-bg-white tw-w-7 tw-text-black tw-text-center tw-p-1 tw-rounded-50%"
                    >
                      <img src={iconItem.icon} alt={iconItem.title} />
                    </a>
                  );
                })}
              </div>
              <div className="payment tw-flex tw-gap-4 1.5xl:tw-gap-2 tw-my-4 tw-mx-auto tw-w-fit">
                {paymentList.map(payment => (
                  <img className="tw-w-12" key={payment.url} src={payment.url} alt={payment.title} />
                ))}
              </div>
            </div>
          </div>
          <div className="tw-mb-10 tw-text-xs tw-font-normal registered tw-text-primary-50 400Min:tw-text-sm ssm:tw-text-base">
            Car.co.uk, registered in England No. 03143909. Registered Office: New Reg Limited, Unit 5, 117 Liverpool
            Road, Longton, Preston, PR4 5AA
          </div>
          <div className="tw-text-xs tw-font-normal tw-text-primary-50 400Min:tw-text-sm ssm:tw-text-base">
            Car.co.uk is a trading name of New Reg Limited which is Authorised and regulated by the Financial Conduct
            Authority (FRN: 626225). New Reg Limited is a loan broker and not a lender. The information contained on
            this website is for editorial purposes only and not intended as financial advice. New Reg Limited has
            partnered with Autoguard Group for the purpose of offering car warranties in accordance with the terms and
            conditions documented on the warranty check-out page.
          </div>
        </div>
      </div>
    </div>
  );
};
FooterV3.propTypes = {
  companyPages: PropTypes.array,
  quickLinkPages: PropTypes.array,
  numberPlatesPages: PropTypes.array,
  insurancePages: PropTypes.array,
  financePages: PropTypes.array,
  scrapMyCarPages: PropTypes.array,
  warrantyPages: PropTypes.arrayOf(PropTypes.shape({})),
  vehicleInspectionsPages: PropTypes.array,
};
export default FooterV3;
