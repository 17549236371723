import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// import Button from 'react-bootstrap/Button';
import { Formik, Form, FastField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { reportToBugsnag } from 'lib/bugsnag';

import { getReQuoteVrm } from 'store/selectors/scrappedCars';

import { instantQuoteValidationSchema, instantQuoteInitialValues } from 'common/formsData/scarMyCarQuoteProcess';
import { createQuoteRequest, dropQuote } from 'store/actions/quote';
import { setReQuoteVrm } from 'store/actions/scrappedCars';
import { getRedirectToStepStatusSelector } from 'store/selectors/redirect';
import { isPostCodeValid } from 'lib/forms';
import PostcodeAutocompleteV2 from '../FormV2/PostcodeAutocompleteV2';
import CustomBaseInputFieldV2 from '../FormV2/CustomBaseInputFieldV2';

const HomePageQuoteForm = ({
  formType,
  buttonText,
  buttonHtml,
  buttonArrow,
  placeholderField,
  placeholderCode,
  quoteType,
}) => {
  const dispatch = useDispatch();
  const isRedirectToStep = useSelector(getRedirectToStepStatusSelector);
  const reQuoteVrm = useSelector(getReQuoteVrm);

  const handleSubmitButton = async (values, { setErrors, setSubmitting, setFieldError }) => {
    const { registrationNumber, postcode } = values;
    // validate postcode is correct
    validatePostcode(postcode).then(isValid => {
      if (isValid) {
        dispatch(dropQuote());
        dispatch(
          createQuoteRequest({
            registrationNumber,
            postcode,
            actions: { setErrors, setSubmitting },
            quoteType,
          }),
        );
        dispatch(setReQuoteVrm(''));
      } else {
        setFieldError('postcode', 'The postcode entered is incorrect');
        setSubmitting(false);
      }
    });
  };

  const validatePostcode = async postcode => {
    let isValid;
    try {
      isValid = await isPostCodeValid(postcode);
    } catch (err) {
      reportToBugsnag(err, 'validate');
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return isValid;
    }
  };

  let formClass;
  let inputGroupClass;
  switch (formType) {
    default:
    case 'inline':
      formClass = 'tw-flex tw-form-inline tw-flex-wrap tw-w-full tw-justify-center';
      inputGroupClass = 'tw-grid tw-grid-cols-1 tw-w-full';
      break;
    case 'line':
      formClass =
        'tw-flex tw-form-line tw-flex-wrap tw-w-full tw-justify-center slg:tw-justify-start slg:tw-flex-nowrap slg:tw-gap-4';
      inputGroupClass = 'tw-grid tw-grid-cols-1 tw-w-full slg:tw-flex slg:tw-flex-nowrap slg:tw-w-auto slg:tw-gap-4';
      break;
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{ ...instantQuoteInitialValues, ...{ registrationNumber: reQuoteVrm || '' } }}
      validationSchema={instantQuoteValidationSchema}
      onSubmit={handleSubmitButton}
      render={({ values, errors, touched, setFieldValue, setFieldTouched, isSubmitting }) => (
        <Form name="scrap-car-quote-front-page" className={formClass}>
          <div className={inputGroupClass}>
            <div>
              <div
                className={cn({
                  'tw-has-error': touched?.registrationNumber && errors?.registrationNumber,
                  'slg:tw-w-[15rem] ': formType === 'line',
                })}
              >
                <FastField
                  type="text"
                  name="registrationNumber"
                  placeholder={placeholderField}
                  customChangeType={{ type: 'uppercase' }}
                  component={CustomBaseInputFieldV2}
                />
                <span> {errors?.registrationNumber} </span>
              </div>
            </div>
            <div
              className={cn({
                'slg:tw-w-[15rem]': formType === 'line',
              })}
            >
              <PostcodeAutocompleteV2
                id="postcode"
                isUppercaseValue
                isUppercaseText
                value={values?.postcode}
                name="postcode"
                placeholder={placeholderCode}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={touched?.postcode && errors?.postcode}
              />
            </div>
          </div>
          <div
            className={cn('tw-flex tw-w-full', {
              'tw-pt-2 tw-justify-center': formType === 'inline',
              'tw-pt-2 slg:tw-pt-0 slg:tw-justify-start tw-justify-center slg:tw-mb-6 slg:tw-w-40 ':
                formType === 'line',
            })}
          >
            <button
              disabled={isSubmitting || isRedirectToStep}
              type="submit"
              className={cn('tw-w-full tw-button-primary', {
                'tw-min-w-[200px] md:tw-max-w-[240px]': formType === 'line',
              })}
            >
              {buttonHtml ? (
                <div className="tw-inline-block" dangerouslySetInnerHTML={{ __html: buttonHtml }}></div>
              ) : (
                <span>{buttonText}</span>
              )}
              {buttonArrow && <div className="tw-mb-px tw-ml-3 tw-border-white tw-arrow-right"></div>}
            </button>
          </div>
        </Form>
      )}
    />
  );
};

HomePageQuoteForm.propTypes = {
  formType: PropTypes.oneOf(['line', 'inline']),
  buttonArrow: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonHtml: PropTypes.string,
  placeholderField: PropTypes.string,
  placeholderCode: PropTypes.string,
};

HomePageQuoteForm.defaultProps = {
  formType: 'inline',
  buttonText: '',
  buttonHtml: '',
  buttonArrow: false,
  placeholderField: 'Car Reg',
  placeholderCode: 'Postcode',
};

export default HomePageQuoteForm;
