import CustomerRateList from './CustomerRateList';
const rateList = [
  {
    id: 'rate_1',
    rate: 5,
    user: 'Vanessa',
    title: 'Fantastic!',
    comment:
      'Would not hesitate to use car.co.uk again. It was such a smooth process from enquiry to collection. Payment was so prompt and in my account before my car was placed on the lorry. The gentleman who collected my car was extremely professional and guided me through the process of changing ownership. Faultless in everyway. It was so refreshing to deal with a company without any hiccups. I will be recommending to friends and family.',
  },
  {
    id: 'rate_2',
    rate: 5,
    user: 'Gunney50',
    title: 'Fast and slick car scrapping',
    comment:
      'I contacted Car.co.Uk to arrange the scrapping of my car. I found the process straight forward and easy. It was all done online they gave me the option of dates for pickup which I found helpful, the also gave me the choice of morning or afternoon pickup. On the day of pickup I was emailed the details of the driver including his mobile number and what documents I needed to have. On the day the driver I found to be both friendly and professional he also dealt with the paperwork and it was all done within 20 minutes. I found the whole experience refreshingly professional. I recommend anyone scrapping their car to use this company.',
  },
  {
    id: 'rate_3',
    rate: 5,
    user: 'Maria',
    title: 'Absolutely great experience!',
    comment:
      'From beginning to end this company was excellent and so easy to use. Their quote was more than double the highest quote I got from any other company and I checked quite a few! It was so easy to arrange a pick up date and it was all clear and well signposted on their website. On the day the driver Jack was quick and efficient. There was no quibble over my quote price and the money was in my account there and then before Jack even finished loading the car on to the tow truck. I then completed my v5 online so it was immediate and that was it! I was worried about it going smoothly e.g would they turn up? Would they try to quibble on the quote? But I must say I had a very calm, easy experience and I would highly recommend them.',
  },
  {
    id: 'rate_4',
    rate: 5,
    user: 'Chris',
    title: 'Rapid service and no price quibbles…',
    comment:
      'Rapid service and no price quibbles when collecting the car. Much smoother experience than other online sales sites I’ve used in the past.',
  },
  {
    id: 'rate_5',
    rate: 5,
    user: 'Frank Allan',
    title: 'Highly Recommended.',
    comment:
      "Whether it was the employees who collected my vehicle or the office staff who took my calls, I found them all to be extremely professional, efficient, courteous, friendly and helpful. So much so that, although I had forgotten to take note of the vehicle's V5C (log book) number, they were happy to assure me that they take care of everything, including registering the vehicle with the DVLA as scrapped. In short, a very good service by a company that I would highly recommend.",
  },
  {
    id: 'rate_6',
    rate: 5,
    user: 'Lorna Wallace',
    title: 'The whole process was straightforward',
    comment:
      'The whole process was straightforward. I was kept updated throughout the process and, especially, on the day of collection. Despite the dreadful weather, the car was collected on time, quickly and efficiently. The process re payment and reporting to DVLA was also explained very clearly too.',
  },
  {
    id: 'rate_7',
    rate: 5,
    user: 'Charlotte',
    title: 'Really professional experience',
    comment:
      'Really professional experience. Prompt and friendly collection and money was in my bank in minutes after saying farewell to my motor. Would recommend.',
  },
  {
    id: 'rate_8',
    rate: 5,
    user: 'Kim Johnson',
    title: 'Easy and pleasant experience',
    comment:
      'Easy and pleasant experience. From entering information to excepting offer (which was also the the best offer I had researched) Clear information on what to do, choice of pick up etc Collection was quick and money was in my bank account before car had left drive.',
  },
  {
    id: 'rate_9',
    rate: 5,
    user: 'Mohammed Billal Iqbal',
    title: 'Service was 5 stars',
    comment: 'Service was 5 stars. Top service. Absolutely amazing to deal with Car.co.uk.',
  },
  {
    id: 'rate_10',
    rate: 5,
    user: 'Andy Hillyard',
    title: 'So it turns out that not everything that seems too good to be true is too good to be true!!!',
    comment:
      'Amazing service throughout. I obtained a quote on Monday and was offered exactly twice the amount that my scrap dealer offered me. I was offered a collection on the same day but went for the next day as the car was at the garage. The driver (Matt) contacted me Tuesday morning to agree the time, and was polite and friendly. The funds were in my bank account as I watched the car being driven away. The entire process was ridiculously fast and smooth. I thoroughly recommend using them if you have a car to scrap.',
  },
];
export { CustomerRateList, rateList };
