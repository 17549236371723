import React from 'react';
import PropTypes from 'prop-types';
import { innerHTML } from 'lib/homePageHelper';
import { CommonLabel } from '../CommonLabel';

const HomePageParagraphHtmlContainer = ({ paragraphTextData }) => {
  const { labelContent, labelColor, textHtml, titleHtml, classNames } = paragraphTextData;
  return paragraphTextData ? (
    <div
      className={`tw-z-20 tw-flex tw-flex-wrap tw-items-start tw-content-start tw-w-full slg:tw-flex-1 tw-bg-grey-light ${classNames}`}
    >
      {labelContent && (
        <CommonLabel content={labelContent} bgColor={labelColor} boldFont customLabelClasses="!tw-text-default" />
      )}
      <div className="tw-w-full">
        <div className="tw-inner-html-container" {...innerHTML(titleHtml)}></div>
        <div className="tw-inner-html-container" {...innerHTML(textHtml)}></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

HomePageParagraphHtmlContainer.propTypes = {
  paragraphTextData: PropTypes.shape({
    classNames: PropTypes.string,
    labelContent: PropTypes.string,
    labelColor: PropTypes.string,
    title: PropTypes.string,
    textHtml: PropTypes.string,
  }),
};

HomePageParagraphHtmlContainer.defaultProps = {
  paragraphTextData: null,
};

export default HomePageParagraphHtmlContainer;
