import React from 'react';
import 'styles/tailwind.scss';
import PropTypes from 'prop-types';
const IconTextBlock = ({ list, icon, type, textColor }) => {
  let iconWidth;
  let textColorClass;
  let containerClass;

  switch (type) {
    case 'noDescription':
    default:
      iconWidth = 24;
      containerClass = 'tw-flex tw-w-60 md:tw-w-full tw-flex-row tw-px-0 md:tw-px-40 slg:tw-px-0 tw-flex-wrap md:tw-flex-no-wrap tw-justify-start md:tw-justify-center slg:tw-justify-between tw-gap-y-6 lg:tw-gap-x-2 md:tw-gap-x-8'
      break;
    case 'withDescription':
      iconWidth = 32;
      containerClass = 'tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-x-16 tw-gap-y-8';
      break;
    case 'noDescriptionFlex':
      iconWidth = 18;
      containerClass = 'tw-flex tw-flex-row tw-flex-wrap sm:tw-flex-no-wrap tw-justify-center tw-gap-4';
      break;
  }

  switch (textColor) {
    default:
      textColorClass = 'tw-text-current';
      break;
    case 'black':
      textColorClass = 'tw-text-black';
      break;
    case 'white':
      textColorClass = 'tw-text-white';
      break;
  }

  if (type === 'withDescription') {
    iconWidth = 32;
  } else {
    iconWidth = 24;
  }

  return (
    <div className={`card-container ${textColorClass} ${containerClass}`}>
      {
        list?.map((item, index) => {
          const isLastItem = index + 1 === list.length;
          return <div key={`${item.title}_${index}`} className={`item tw-flex ${type === 'withDescription' ? 'tw-items-start' : 'tw-items-center'} tw-gap-2
          ${type === 'noDescription' && `lg:tw-mx-auto md:first:tw-ml-0 md:tw-ml-0 ${isLastItem && 'lg:tw-col-auto md:tw-col-span-full md:last:tw-mr-0'}`} `}>
            <img className='icon' width={iconWidth} src={icon} />
            <div className='tw-flex-1 text'>
              {item.title && <div className="tw-text-base tw-font-bold title xl:tw-text-sxl">{item.title}</div>}
              {item.subTitle && <div className="tw-font-semibold sub-title slg:tw-text-xs 1.5xl:tw-text-sm">{item.subTitle}</div>}
              {item.description && <div className='description tw-text-grey-700'>{item.description}</div>}
            </div>
          </div>
        })
      }
    </div>
  );
}
IconTextBlock.propTypes = {
  icon: PropTypes.string,
  textColor: PropTypes.oneOf(['green', 'white', 'black']),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      subTitle: PropTypes.string,
    }),
  ),
  type: PropTypes.oneOf(['noDescription', 'noDescriptionFlex', 'withDescription'])
};
IconTextBlock.defaultProps = {
  list: [],
  textColor: null,
  type: 'noDescription',
}
export default IconTextBlock;
