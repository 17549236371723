import React from 'react';
import PropTypes from 'prop-types';

const HomePageIntroSectionTitle = ({ title, hideMargin = false, limitWidth = true }) => {
  return (
    <>
      <section className={`${hideMargin ? '' : 'tw-mb-10'} tw-text-center tw-select-none`}>
        <h2>
          <strong>{title?.mainContent}</strong>
        </h2>
        {title?.subContent && (
          <p className={`tw-mx-auto tw-text-gray-400 tw-text-default ${limitWidth ? 'tw-max-w-[18rem]' : ''}`}>
            {title?.subContent}
          </p>
        )}
      </section>
    </>
  );
};

HomePageIntroSectionTitle.propTypes = {
  title: PropTypes.shape({
    mainContent: PropTypes.string,
    subContent: PropTypes.string,
  }),
  hideMargin: PropTypes.bool,
  limitWidth: PropTypes.bool,
};

HomePageIntroSectionTitle.defaultProps = {
  title: {},
};

export default HomePageIntroSectionTitle;
