import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { trustPilotLogo, trustPilotLogoDark } from 'public/images/icons/index';

export const RateWidget = ({ isWidget, widgetType, theme = 'dark', allowRedirect = true }) => {
  const widgetRef = useRef(null);
  useEffect(() => {
    if (isWidget) {
      const renderWidget = () => {
        const trustbox = widgetRef.current;
        window.Trustpilot.loadFromElement(trustbox);
      };
      if (window.Trustpilot) {
        renderWidget();
      } else {
        const aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        aScript.async = 'true';
        document.head.appendChild(aScript);
        aScript.onload = () => {
          renderWidget();
        };
      }
    }
  }, [isWidget]);

  const renderWidget = type => {
    let templateId;
    let businessUnitId;
    let height;
    let width;

    switch (type) {
      case 'horizontal': {
        switch (theme) {
          default:
          case 'light': {
            templateId = '5406e65db0d04a09e042d5fc';
            businessUnitId = '5d4aaaee0218d10001ce43f3';
            height = '28px';
            width = '100%';
            break;
          }
          case 'dark': {
            templateId = '5406e65db0d04a09e042d5fc';
            businessUnitId = '5d4aaaee0218d10001ce43f3';
            height = '22px';
            width = '100%';
            break;
          }
        }
        break;
      }
      case 'mini': {
        switch (theme) {
          default:
          case 'light': {
            templateId = '53aa8807dec7e10d38f59f32';
            businessUnitId = '5d4aaaee0218d10001ce43f3';
            height = '120px';
            width = '100%';
            break;
          }
          case 'dark': {
            templateId = '53aa8807dec7e10d38f59f32';
            businessUnitId = '5d4aaaee0218d10001ce43f3';
            height = '120px';
            width = '100%';
            break;
          }
        }
        break;
      }
      default: {
        templateId = null;
        break;
      }
    }
    return templateId ? (
      <div
        ref={widgetRef}
        data-locale="en-GB"
        data-template-id={templateId}
        data-businessunit-id={businessUnitId}
        data-style-height={height}
        data-style-width={width}
        data-theme={theme}
        data-font-family="Montserrat"
      ></div>
    ) : (
      <></>
    );
  };

  const pointEventsClass = allowRedirect ? 'tw-pointer-events-auto' : 'tw-pointer-events-none';

  const trustPilotLink = 'https://uk.trustpilot.com/review/car.co.uk';
  const trustPilotRateLogo = 'https://stage-drupal.car.co.uk/s3fs-public/2020-02/Trustpilot_ratings_5star-RGB.png';
  const isBrowser = typeof window !== 'undefined';
  const openTrustPilotLink = () => {
    isBrowser && window.open(trustPilotLink);
  };

  return (
    <div className={`${pointEventsClass}`}>
      {isWidget ? (
        renderWidget(widgetType)
      ) : (
        <div className="tw-flex tw-justify-center tw-w-full">
          {theme === 'dark' ? (
            <div
              onClick={openTrustPilotLink}
              className="tw-cursor-pointer tw-w-[20rem] md:tw-w-[25rem] tw-flex tw-justify-between tw-gap-x-1 tw-gap-y-4 tw-items-center tw-flex-wrap md:tw-flex-nowrap"
            >
              <div className="tw-w-full tw-tracking-wide tw-text-center md:tw-text-left md:tw-w-auto tw-text-ssm">
                <span className="tw-font-light tw-text-white">
                  Rated <strong>4.9/5</strong> from <strong>6,115</strong> reviews
                </span>
              </div>
              <div className="tw-h-[1.2rem] tw-flex tw-flex-nowrap tw-justify-center md:tw-justify-between tw-gap-3 tw-w-full md:tw-w-auto">
                <div className="tw-h-full">
                  <img className="tw-h-full" src={trustPilotLogo} alt="trust pilot logo" />
                </div>
                <div className="tw-flex tw-flex-row-reverse tw-h-full">
                  <img className="tw-h-full" src={trustPilotRateLogo} alt="trust pilot rating logo" />
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={openTrustPilotLink}
              className="tw-cursor-pointer tw-w-[23rem] tw-flex tw-justify-between tw-gap-y-4 tw-items-center tw-flex-wrap"
            >
              <div className="tw-flex tw-justify-center tw-w-full tw-tracking-wide tw-text-left tw-text-default tw-gap-x-2 tw-flex-nowrap">
                <span className="tw-font-light tw-text-primary-mid">All reviews are verified on</span>
                <div className="tw-h-[1.2rem]">
                  <img className="tw-h-full" src={trustPilotLogoDark} alt="trust pilot logo" />
                </div>
              </div>
              <div className="tw-h-[1.4rem] tw-flex tw-flex-nowrap tw-justify-center tw-gap-x-2 md:tw-gap-x-4 tw-w-full">
                <span className="tw-font-light tw-text-primary-mid">
                  Rated <strong>4.9/5</strong> from <strong>6,115</strong> reviews
                </span>
                <div className="tw-flex tw-flex-row-reverse tw-h-full">
                  <img className="tw-h-full" src={trustPilotRateLogo} alt="trust pilot rating logo" />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Rate is responsive for RateWidget
const RateV2 = ({ allowRedirect, rateType, theme, isWidget = false }) => {
  return (
    <div
      className={cn('tw-rate-container', {
        'tw-py-3': rateType === 'default',
        'tw-rate-auto-fit slg:tw-translate-x-[-19.5rem]': rateType === 'left',
      })}
    >
      <div className="tw-hidden md:tw-block tw-min-h-[28px]">
        <RateWidget isWidget={isWidget} widgetType="horizontal" allowRedirect={allowRedirect} theme={theme} />
      </div>
      <div className="tw-block md:tw-hidden">
        <RateWidget isWidget={isWidget} widgetType="mini" allowRedirect={allowRedirect} theme={theme} />
      </div>
    </div>
  );
};

RateV2.propTypes = {
  rateType: PropTypes.oneOf(['default', 'left']),
  allowRedirect: PropTypes.bool,
};

RateV2.defaultProps = {
  rateType: 'default',
  allowRedirect: true,
};

export default RateV2;
