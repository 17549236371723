import * as Yup from 'yup';
import { commonAlphaFieldMaxValue, password, contactNumber } from '../../user';

const address = (addressName, isRequired) =>
  isRequired
    ? Yup.string()
        .trim()
        .max(100, `The ${addressName} should be maximum 100 characters long`)
        .required(`The ${addressName} should not be empty`)
    : Yup.string().max(100, `The ${addressName} should be maximum 100 characters long`);

const preferredCollectionDateTime = Yup.object({
  date: Yup.string().required(
    'No preferred collection time has been chosen. Please select an available (green) collection time.',
  ),
  startTime: Yup.string().required(
    'No preferred collection time has been chosen. Please select an available (green) collection time.',
  ),
  endTime: Yup.string().required(
    'No preferred collection time has been chosen. Please select an available (green) collection time.',
  ),
}).required('No preferred collection time has been chosen. Please select an available (green) collection time.');

export const arrangeACollectionValidationSchema = Yup.object({
  firstName: commonAlphaFieldMaxValue('first name', 40),
  lastName: commonAlphaFieldMaxValue('last name', 40),
  phoneNumber: contactNumber(true),
  postcode: Yup.string(),
  preferredCollectionDateTime,
});

export const arrangeACollectionValidationSchemaManualAddress = Yup.object({
  address1: address('address line 1', true),
  address2: address('address line 2', false),
  town: Yup.string()
    .trim()
    .max(100, 'The town should be maximum 100 characters long')
    .required('The town should not be empty'),
  county: Yup.string()
    .trim()
    .max(100, 'The county should be maximum 100 characters long')
    .required('The county should not be empty'),
});

export const arrangeACollectionValidationSchemaAddressByPostcode = Yup.object({
  address: Yup.string().required('Address not selected. Please select your address from the dropdown above'),
});

export const arrangeACollectionValidationSchemaPassword = Yup.object({
  password,
  terms: Yup.boolean()
    .required('Please, accept terms and conditions')
    .oneOf([true], 'Please, accept terms and conditions'),
});

export const getArrangeCollectionErrorMsg = errors => {
  if (JSON.stringify(errors) !== {}) {
    if (errors.firstName) return errors.firstName;
    if (errors.lastName) return errors.lastName;
    if (errors.phoneNumber) return errors.phoneNumber;
    if (errors.address) return errors.address;
    if (errors.preferredCollectionDateTime) return errors.preferredCollectionDateTime.date;
    if (errors.password) return errors.password;
    if (errors.terms) return errors.terms;
    if (errors.town) return errors.town;
  }
  return '';
};
