import React from 'react';
import PropTypes from 'prop-types';
import RateV2 from 'components/RateV2/RateV2';
import IconTextBlock from 'components/IconTextBlock/IconTextBlock';
import AIIcon from './AiIcon';
import HomePageQuoteForm from './HomePageQuoteForm';
import BrandBar from './BrandBar';

const HomePageTopQuoteBanner = ({ bannerData }) => {
  const { title, description, pointTextData, bgItems, aiIconData, brandBarData } = bannerData;
  return bannerData ? (
    <div className="tw-top-quote-banner-container">
      <div className="tw-relative tw-w-full tw-border-t tw-border-grey-topBorder tw-bg-primary-500">
        <section className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-overflow-hidden">
          {bgItems &&
            bgItems.map(item => {
              return <img key={item.key} className={item.classNames} alt="Banner Background" src={item.src} />;
            })}
        </section>
        <div className="tw-container tw-relative tw-z-10 tw-px-6 tw-pt-8 tw-pb-12 tw-mx-auto sm:tw-pt-16 sm:tw-pb-8 sm:tw-px-0">
          <section className="tw-text-center tw-text-white">
            <h1>
              <strong>{title}</strong>
            </h1>
            <p className="tw--mt-2 tw-font-light tw-text-primary-50">{description}</p>
          </section>

          <section className="tw-w-[20.5rem] md:tw-w-[36rem] tw-mx-auto">
            <IconTextBlock
              type="noDescriptionFlex"
              list={pointTextData?.textList}
              textColor="white"
              icon={pointTextData?.icon}
            ></IconTextBlock>
          </section>

          <section className="tw-w-full sm:tw-w-[22rem] tw-pt-8 tw-mx-auto">
            <HomePageQuoteForm
              formType="inline"
              placeholderField="ENTER REG"
              placeholderCode="ENTER POST CODE"
              buttonHtml="<strong>GET INSTANT PRICE</strong>"
              buttonArrow
            ></HomePageQuoteForm>
          </section>

          <section className="tw-w-full tw-pt-4 tw-mx-auto md:tw-pt-8">
            <RateV2 theme="dark" />
          </section>

          <AIIcon aiIconData={aiIconData} />
        </div>
      </div>
      <BrandBar brandBarIconList={brandBarData?.iconList} />
    </div>
  ) : (
    <></>
  );
};

HomePageTopQuoteBanner.propTypes = {
  bannerData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    bgItems: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        src: PropTypes.string,
        classNames: PropTypes.string,
      }),
    ),
    pointTextData: PropTypes.shape({
      icon: PropTypes.string,
      textList: PropTypes.arrayOf(
        PropTypes.shape({
          subTitle: PropTypes.string,
        }),
      ),
    }),
    brandBarData: PropTypes.shape({
      iconList: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          icon: PropTypes.string,
          classNames: PropTypes.string,
        }),
      ),
    }),
    aiIconData: PropTypes.shape({
      classNames: PropTypes.string,
      icon: PropTypes.string,
      textHtml: PropTypes.string,
    }),
  }),
};

HomePageTopQuoteBanner.defaultProps = {
  bannerData: null,
};

export default HomePageTopQuoteBanner;
