import React from 'react';
import PropTypes from 'prop-types';
import CommonLabel from 'components/CommonLabel/CommonLabel';

const renderLabel = (classNames, content, bgColor) => {
  return (
    <div className={`tw-flex tw-w-full tw-flex-nowrap ${classNames}`}>
      <div className="tw-hidden ssm:tw-block">
        <CommonLabel content={content} labelStyle="default" bgColor={bgColor} labelSize="small" />
      </div>
      <div className="tw-block ssm:tw-hidden">
        <CommonLabel
          content={content}
          labelStyle="default"
          bgColor={bgColor}
          labelSize="default"
          customLabelClasses="!tw-text-default"
        />
      </div>
    </div>
  );
};

const ParagraphNavCard = ({ cardData, cardStyle }) => {
  let cardBoxClass;

  switch (cardStyle) {
    default:
    case 'default':
      cardBoxClass = 'tw-h-64 sxl:tw-h-72 sm:tw-h-56 ssm:tw-h-52';
      break;
    case 'withLabel':
      cardBoxClass = 'tw-h-64 ssm:tw-h-56';
      break;
  }

  return cardData ? (
    <a
      href={cardData.link}
      className={`tw-relative tw-flex tw-items-center tw-overflow-hidden tw-cursor-pointer tw-group tw-rounded-xl ${cardBoxClass}`}
    >
      <div
        className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-transition-all tw-duration-500 tw-scale-100 tw-bg-center tw-bg-no-repeat tw-bg-cover group-hover:tw-scale-105"
        style={{ 'background-image': `url(${cardData.bgUrl})` }}
      ></div>
      <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-gradient-to-b tw-from-transparent tw-to-black tw-opacity-40"></div>
      <div className="tw-z-10 tw-flex tw-flex-wrap tw-justify-between tw-h-full tw-px-6 tw-py-6 ssm:tw-px-4 ssm:tw-py-4">
        {/* {cardStyle === 'withLabel' && renderLabel('tw-items-start tw-justify-end', cardData?.label, 'lightBlue')} */}
        <div className="tw-flex tw-flex-wrap tw-items-end tw-content-end tw-justify-start tw-w-full tw-gap-0 tw-mt-12 tw-gap-y-0">
          <span className="tw-mb-4 tw-text-xl tw-font-semibold tw-text-left tw-text-white ssm:tw-text-base group-hover:tw-underline">
            {cardData.title}
          </span>
          {cardStyle === 'withLabel' && renderLabel('tw-justify-start', cardData?.date, 'green')}
        </div>
      </div>
    </a>
  ) : (
    <></>
  );
};

ParagraphNavCard.propTypes = {
  cardStyle: PropTypes.oneOf(['default', 'withLabel']),
  cardData: PropTypes.shape({
    bgUrl: PropTypes.string,
    label: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
  }),
};

ParagraphNavCard.defaultProps = {
  cardData: null,
  cardStyle: 'default',
};

export default ParagraphNavCard;
